var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v1.137.0 (16d1f07)"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initSentry } from "@/lib/instrumentation";
import { getEnvironment } from "@/lib/utils";
import config from "@/server/config";

const environment = getEnvironment();

initSentry(config.SENTRY_DSN(), environment, config.SENTRY_TRACE_RATE());
