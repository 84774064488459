import {
  CountryData,
  LookupCountryData,
  LookupSupportedCountries,
  ReverseLookupCountryData,
  ReverseLookupSupportedCountries,
} from "./types";

export const stubCountries: CountryData[] = [
  {
    name: "Andorra",
    id: "ad",
    alpha3code: "AND",
    supportedByProducts: ["returns"],
  },
  {
    name: "Afghanistan",
    id: "af",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "AFG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Antigua and Barbuda",
    id: "ag",
    alpha3code: "ATG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Anguilla",
    id: "ai",
    alpha3code: "AIA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Albania",
    id: "al",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ALB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Armenia",
    id: "am",
    alpha3code: "ARM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Angola",
    id: "ao",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "AGO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Antarctica",
    id: "aq",
    alpha3code: "ATA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Argentina",
    id: "ar",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ARG",
    supportedByProducts: ["returns"],
  },
  {
    name: "American Samoa",
    id: "as",
    alpha3code: "ASM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Australia",
    id: "au",
    supportedByProducts: ["returns"],
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "AUS",
  },
  {
    name: "Austria",
    id: "at",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "AUT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Aruba",
    id: "aw",
    alpha3code: "ABW",
    supportedByProducts: ["returns"],
  },
  {
    name: "Åland Islands",
    id: "ax",
    alpha3code: "ALA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Azerbaidjan",
    id: "az",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "AZE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bosnia and Herzegovina",
    id: "ba",
    alpha3code: "BIH",
    supportedByProducts: ["returns"],
  },
  {
    name: "Barbados",
    id: "bb",
    alpha3code: "BRB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bahrain",
    id: "bh",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "BHR",
  },
  {
    name: "Bangladesh",
    id: "bd",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BGD",
    supportedByProducts: ["returns"],
  },
  {
    name: "Benin",
    id: "bj",
    alpha3code: "BEN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Barthélemy",
    id: "bl",
    alpha3code: "BLM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bermuda",
    id: "bm",
    alpha3code: "BMU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Brunei",
    id: "bn",
    alpha3code: "BRN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    id: "bq",
    alpha3code: "BES",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bahamas",
    id: "bs",
    alpha3code: "BHS",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bhutan",
    id: "bt",
    alpha3code: "BTN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bouvet Island",
    id: "bv",
    alpha3code: "BVT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Botswana",
    id: "bw",
    alpha3code: "BWA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Belarus",
    id: "by",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BLR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Belgium",
    id: "be",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "BEL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bolivia",
    id: "bo",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BOL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Brazil",
    id: "br",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "BRA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Bulgaria",
    id: "bg",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BGR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Burkina Faso",
    id: "bf",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BFA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Burundi",
    id: "bi",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "BDI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Belize",
    id: "bz",
    alpha3code: "BLZ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cocos (Keeling) Islands",
    id: "cc",
    alpha3code: "CCK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Democratic Republic of the Congo",
    id: "cd",
    alpha3code: "COD",
    supportedByProducts: ["returns"],
  },
  {
    name: "Central African Republic",
    id: "cf",
    alpha3code: "CAF",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cook Islands",
    id: "ck",
    alpha3code: "COK",
    supportedByProducts: ["returns"],
  },
  {
    name: "China",
    id: "cn",
    alpha3code: "CHN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cuba",
    id: "cu",
    alpha3code: "CUB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cabo Verde",
    id: "cv",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "CPV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cameroon",
    id: "cm",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "CMR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Canada",
    id: "ca",
    supportedByProducts: ["returns"],
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "name" },
        { required: false, name: "date" },
      ],
    },
    alpha3code: "CAN",
  },
  {
    name: "Chile",
    id: "cl",
    supportedByProducts: ["returns"],
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "CHL",
  },
  {
    name: "Colombia",
    id: "co",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "COL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Costa Rica",
    id: "cr",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "CRI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Curaçao",
    id: "cw",
    alpha3code: "CUW",
    supportedByProducts: ["returns"],
  },
  {
    name: "Christmas Island",
    id: "cx",
    alpha3code: "CXR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Djibouti",
    id: "dj",
    alpha3code: "DJI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Dominica",
    id: "dm",
    alpha3code: "DMA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Algeria",
    id: "dz",
    alpha3code: "DZA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Egypt",
    id: "eg",
    alpha3code: "EGY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Western Sahara",
    id: "eh",
    alpha3code: "ESH",
    supportedByProducts: ["returns"],
  },
  {
    name: "Eritrea",
    id: "er",
    alpha3code: "ERI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Ethiopia",
    id: "et",
    alpha3code: "ETH",
    supportedByProducts: ["returns"],
  },
  {
    name: "Fiji",
    id: "fj",
    alpha3code: "FJI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Falkland Islands",
    id: "fk",
    alpha3code: "FLK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Federated States of Micronesia",
    id: "fm",
    alpha3code: "FSM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Faroe Islands",
    id: "fo",
    alpha3code: "FRO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Gabon",
    id: "ga",
    alpha3code: "GAB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Grenada",
    id: "gd",
    alpha3code: "GRD",
    supportedByProducts: ["returns"],
  },
  {
    name: "French Guiana",
    id: "gf",
    alpha3code: "GUF",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guernsey",
    id: "gg",
    alpha3code: "GGY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Gibraltar",
    id: "gi",
    alpha3code: "GIB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Greenland",
    id: "gl",
    alpha3code: "GRL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Gambia",
    id: "gm",
    alpha3code: "GMB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guinea",
    id: "gn",
    alpha3code: "GIN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guadeloupe",
    id: "gp",
    alpha3code: "GLP",
    supportedByProducts: ["returns"],
  },
  {
    name: "Equatorial Guinea",
    id: "gq",
    alpha3code: "GNQ",
    supportedByProducts: ["returns"],
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    id: "gs",
    alpha3code: "SGS",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guam",
    id: "gu",
    alpha3code: "GUM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guinea-Bissau",
    id: "gw",
    alpha3code: "GNB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guyana",
    id: "gy",
    alpha3code: "GUY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Hong Kong",
    id: "hk",
    alpha3code: "HKG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Heard Island and McDonald Islands",
    id: "hm",
    alpha3code: "HMD",
    supportedByProducts: ["returns"],
  },
  {
    name: "Croatia",
    id: "hr",
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "date" },
      ],
    },
    alpha3code: "HRV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cyprus",
    id: "cy",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "CYP",
    supportedByProducts: ["returns"],
  },
  {
    name: "Czech Republic",
    id: "cz",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "CZE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Côte d'Ivoire",
    id: "ci",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "CIV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Denmark",
    id: "dk",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "DNK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Dominican Republic",
    id: "do",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "DOM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Ecuador",
    id: "ec",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ECU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Estonia",
    id: "ee",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "EST",
    supportedByProducts: ["returns"],
  },
  {
    name: "Finland",
    id: "fi",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "FIN",
    supportedByProducts: ["returns"],
  },
  {
    name: "France",
    id: "fr",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "FRA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Germany",
    id: "de",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "DEU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Georgia",
    id: "ge",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "GEO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Ghana",
    id: "gh",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "GHA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Greece",
    id: "gr",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "GRC",
    supportedByProducts: ["returns"],
  },
  {
    name: "Guatemala",
    id: "gt",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "GTM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Honduras",
    id: "hn",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "HND",
    supportedByProducts: ["returns"],
  },
  {
    name: "Haiti",
    id: "ht",
    alpha3code: "HTI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Hungary",
    id: "hu",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "HUN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Isle of Man",
    id: "im",
    alpha3code: "IMN",
    supportedByProducts: ["returns"],
  },
  {
    name: "British Indian Ocean Territory",
    id: "io",
    alpha3code: "IOT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Iraq",
    id: "iq",
    alpha3code: "IRQ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Iran",
    id: "ir",
    alpha3code: "IRN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Iceland",
    id: "is",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ISL",
    supportedByProducts: ["returns"],
  },
  {
    name: "India",
    id: "in",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
        {
          required: false,
          name: "india_state_name",
          choices: [
            { id: "", name: "" },
            { id: "Jammu & Kashmir", name: "Jammu & Kashmir" },
            { id: "Himachal Pradesh", name: "Himachal Pradesh" },
            { id: "Punjab", name: "Punjab" },
            { id: "Chandigarh", name: "Chandigarh" },
            { id: "Uttarakhand", name: "Uttarakhand" },
            { id: "Haryana", name: "Haryana" },
            { id: "Delhi", name: "Delhi" },
            { id: "Rajasthan", name: "Rajasthan" },
            { id: "Uttar Pradesh", name: "Uttar Pradesh" },
            { id: "Bihar", name: "Bihar" },
            { id: "Sikkim", name: "Sikkim" },
            { id: "Arunachal Pradesh", name: "Arunachal Pradesh" },
            { id: "Nagaland", name: "Nagaland" },
            { id: "Manipur", name: "Manipur" },
            { id: "Mizoram", name: "Mizoram" },
            { id: "Tripura", name: "Tripura" },
            { id: "Meghalaya", name: "Meghalaya" },
            { id: "Assam", name: "Assam" },
            { id: "West Bengal", name: "West Bengal" },
            { id: "Jharkhand", name: "Jharkhand" },
            { id: "Orissa", name: "Orissa" },
            { id: "Chhattisgarh", name: "Chhattisgarh" },
            { id: "Madhya Pradesh", name: "Madhya Pradesh" },
            { id: "Gujarat", name: "Gujarat" },
            {
              id: "Dadra and Nagar Haveli and Daman and Diu",
              name: "Dadra and Nagar Haveli and Daman and Diu",
            },
            { id: "Maharashtra", name: "Maharashtra" },
            { id: "Karnataka", name: "Karnataka" },
            { id: "Goa", name: "Goa" },
            { id: "Lakshadweep", name: "Lakshadweep" },
            { id: "Kerala", name: "Kerala" },
            { id: "Tamil Nadu", name: "Tamil Nadu" },
            { id: "Puducherry", name: "Puducherry" },
            { id: "Andaman and Nicobar", name: "Andaman and Nicobar" },
            { id: "Telangana", name: "Telangana" },
            { id: "Andhra Pradesh", name: "Andhra Pradesh" },
            { id: "Ladakh", name: "Ladakh" },
          ],
        },
      ],
    },
    alpha3code: "IND",
    supportedByProducts: ["returns"],
  },
  {
    name: "Indonesia",
    id: "id",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "IDN",
  },
  {
    name: "Ireland",
    id: "ie",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "IRL",
  },
  {
    name: "Israel",
    id: "il",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "ISR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Italy",
    id: "it",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ITA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Jersey",
    id: "je",
    alpha3code: "JEY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Jamaica",
    id: "jm",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "JAM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Jordan",
    id: "jo",
    alpha3code: "JOR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Japan",
    id: "jp",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "JPN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Kyrgyzstan",
    id: "kg",
    alpha3code: "KGZ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cambodia",
    id: "kh",
    alpha3code: "KHM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Kiribati",
    id: "ki",
    alpha3code: "KIR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Comoros",
    id: "km",
    alpha3code: "COM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Kitts and Nevis",
    id: "kn",
    alpha3code: "KNA",
    supportedByProducts: ["returns"],
  },
  {
    name: "North Korea",
    id: "kp",
    alpha3code: "PRK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Kuwait",
    id: "kw",
    alpha3code: "KWT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Cayman Islands",
    id: "ky",
    alpha3code: "CYM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Kazakhstan",
    id: "kz",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "KAZ",
  },
  {
    name: "Kenya",
    id: "ke",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "KEN",
  },
  {
    name: "Lao People's Democratic Republic",
    id: "la",
    alpha3code: "LAO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Lebanon",
    id: "lb",
    alpha3code: "LBN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Lucia",
    id: "lc",
    alpha3code: "LCA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Liechtenstein",
    id: "li",
    alpha3code: "LIE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sri Lanka",
    supportedByProducts: ["returns"],
    id: "lk",
    alpha3code: "LKA",
  },
  {
    name: "Liberia",
    id: "lr",
    alpha3code: "LBR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Lesotho",
    id: "ls",
    alpha3code: "LSO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Latvia",
    id: "lv",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "LVA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Lithuania",
    id: "lt",
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "name" },
      ],
    },
    alpha3code: "LTU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Luxembourg",
    id: "lu",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "LUX",
    supportedByProducts: ["returns"],
  },
  {
    name: "Libya",
    id: "ly",
    alpha3code: "LBY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Monaco",
    id: "mc",
    alpha3code: "MCO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Montenegro",
    id: "me",
    alpha3code: "MNE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Collectivity of Saint Martin",
    id: "mf",
    alpha3code: "MAF",
    supportedByProducts: ["returns"],
  },
  {
    name: "Madagascar",
    id: "mg",
    alpha3code: "MDG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Marshall Islands",
    id: "mh",
    alpha3code: "MHL",
    supportedByProducts: ["returns"],
  },
  {
    name: "North Macedonia",
    id: "mk",
    alpha3code: "MKD",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mali",
    id: "ml",
    alpha3code: "MLI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mongolia",
    id: "mn",
    alpha3code: "MNG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Macao",
    id: "mo",
    alpha3code: "MAC",
    supportedByProducts: ["returns"],
  },
  {
    name: "Northern Mariana Islands",
    id: "mp",
    alpha3code: "MNP",
    supportedByProducts: ["returns"],
  },
  {
    name: "Martinique",
    id: "mq",
    alpha3code: "MTQ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mauritania",
    id: "mr",
    alpha3code: "MRT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Montserrat",
    id: "ms",
    alpha3code: "MSR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mauritius",
    id: "mu",
    alpha3code: "MUS",
    supportedByProducts: ["returns"],
  },
  {
    name: "Maldives",
    id: "mv",
    alpha3code: "MDV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Malawi",
    id: "mw",
    alpha3code: "MWI",
    supportedByProducts: ["returns"],
  },
  {
    name: "Malaysia",
    id: "my",
    supportedByProducts: ["returns"],
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "MYS",
  },
  {
    name: "Malta",
    id: "mt",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "MLT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mexico",
    id: "mx",
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "name" },
        { required: false, name: "zip_code" },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "MEX",
  },
  {
    name: "Moldova",
    id: "md",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "MDA",
  },
  {
    name: "Morocco",
    id: "ma",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "MAR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Myanmar",
    id: "mm",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "MMR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mozambique",
    id: "mz",
    alpha3code: "MOZ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Namibia",
    id: "na",
    alpha3code: "NAM",
    supportedByProducts: ["returns"],
  },
  {
    name: "New Caledonia",
    id: "nc",
    alpha3code: "NCL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Niger",
    id: "ne",
    alpha3code: "NER",
    supportedByProducts: ["returns"],
  },
  {
    name: "Norfolk Island",
    id: "nf",
    alpha3code: "NFK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Nicaragua",
    id: "ni",
    alpha3code: "NIC",
    supportedByProducts: ["returns"],
  },
  {
    name: "Nepal",
    id: "np",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "NPL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Netherlands",
    id: "nl",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "NLD",
  },
  {
    name: "Nauru",
    id: "nr",
    alpha3code: "NRU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Niue",
    id: "nu",
    alpha3code: "NIU",
    supportedByProducts: ["returns"],
  },
  {
    name: "New Zealand",
    id: "nz",
    supportedByProducts: ["returns"],
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "NZL",
  },
  {
    name: "Nigeria",
    id: "ng",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "NGA",
  },
  {
    name: "Panama",
    id: "pa",
    alpha3code: "PAN",
    supportedByProducts: ["returns"],
  },
  {
    name: "French Polynesia",
    id: "pf",
    supportedByProducts: ["returns"],
    alpha3code: "PYF",
  },
  {
    name: "Papua New Guinea",
    id: "pg",
    alpha3code: "PNG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Philippines",
    id: "ph",
    alpha3code: "PHL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Pierre and Miquelon",
    id: "pm",
    alpha3code: "SPM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Pitcairn",
    id: "pn",
    alpha3code: "PCN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Puerto Rico",
    id: "pr",
    alpha3code: "PRI",
    supportedByProducts: ["returns"],
  },
  {
    name: "State of Palestine",
    id: "ps",
    alpha3code: "PSE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Palau",
    id: "pw",
    alpha3code: "PLW",
    supportedByProducts: ["returns"],
  },
  {
    name: "Qatar",
    id: "qa",
    alpha3code: "QAT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Réunion",
    id: "re",
    alpha3code: "REU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Rwanda",
    id: "rw",
    alpha3code: "RWA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Solomon Islands",
    id: "sb",
    alpha3code: "SLB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sudan",
    id: "sd",
    alpha3code: "SDN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    id: "sh",
    alpha3code: "SHN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Svalbard and Jan Mayen",
    id: "sj",
    alpha3code: "SJM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sierra Leone",
    id: "sl",
    alpha3code: "SLE",
    supportedByProducts: ["returns"],
  },
  {
    name: "San Marino",
    id: "sm",
    alpha3code: "SMR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Somalia",
    id: "so",
    alpha3code: "SOM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Suriname",
    id: "sr",
    alpha3code: "SUR",
    supportedByProducts: ["returns"],
  },
  {
    name: "South Sudan",
    id: "ss",
    alpha3code: "SSD",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sao Tome and Principe",
    id: "st",
    alpha3code: "STP",
    supportedByProducts: ["returns"],
  },
  {
    name: "El Salvador",
    id: "sv",
    alpha3code: "SLV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sint Maarten",
    id: "sx",
    alpha3code: "SXM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Syria",
    id: "sy",
    alpha3code: "SYR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Eswatini",
    id: "sz",
    alpha3code: "SWZ",
    supportedByProducts: ["returns"],
  },
  {
    name: "Turks and Caicos Islands",
    id: "tc",
    alpha3code: "TCA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Chad",
    id: "td",
    alpha3code: "TCD",
    supportedByProducts: ["returns"],
  },
  {
    name: "French Southern Territories",
    id: "tf",
    alpha3code: "ATF",
    supportedByProducts: ["returns"],
  },
  {
    name: "Togo",
    id: "tg",
    alpha3code: "TGO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tajikistan",
    id: "tj",
    alpha3code: "TJK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tokelau",
    id: "tk",
    alpha3code: "TKL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Timor-Leste",
    id: "tl",
    alpha3code: "TLS",
    supportedByProducts: ["returns"],
  },
  {
    name: "Turkmenistan",
    id: "tm",
    alpha3code: "TKM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tunisia",
    id: "tn",
    alpha3code: "TUN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tonga",
    id: "to",
    alpha3code: "TON",
    supportedByProducts: ["returns"],
  },
  {
    name: "Trinidad and Tobago",
    id: "tt",
    alpha3code: "TTO",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tuvalu",
    id: "tv",
    alpha3code: "TUV",
    supportedByProducts: ["returns"],
  },
  {
    name: "Tanzania",
    id: "tz",
    alpha3code: "TZA",
    supportedByProducts: ["returns"],
  },
  {
    name: "United States Minor Outlying Islands",
    id: "um",
    alpha3code: "UMI",
    supportedByProducts: ["returns"],
  },
  {
    name: "United States of America",
    id: "us",
    alpha3code: "USA",
    supportedByProducts: ["returns"],
  },
  {
    name: "Uzbekistan",
    id: "uz",
    alpha3code: "UZB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Vatican City",
    id: "va",
    alpha3code: "VAT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saint Vincent and the Grenadines",
    id: "vc",
    alpha3code: "VCT",
    supportedByProducts: ["returns"],
  },
  {
    name: "British Virgin Islands",
    id: "vg",
    alpha3code: "VGB",
    supportedByProducts: ["returns"],
  },
  {
    name: "United States Virgin Islands",
    id: "vi",
    alpha3code: "VIR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Vanuatu",
    id: "vu",
    alpha3code: "VUT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Wallis and Futuna",
    id: "wf",
    alpha3code: "WLF",
    supportedByProducts: ["returns"],
  },
  {
    name: "Samoa",
    id: "ws",
    alpha3code: "WSM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Northern Ireland",
    id: "xi",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "NIR",
    supportedByProducts: ["returns"],
  },
  {
    name: "Norway",
    id: "no",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "NOR",
  },
  {
    name: "Oman",
    id: "om",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "OMN",
  },
  {
    name: "Pakistan",
    id: "pk",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "PAK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Paraguay",
    id: "py",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "PRY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Peru",
    id: "pe",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "PER",
    supportedByProducts: ["returns"],
  },
  {
    name: "Poland",
    id: "pl",
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "date" },
      ],
    },
    alpha3code: "POL",
    supportedByProducts: ["returns"],
  },
  {
    name: "Portugal",
    id: "pt",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "PRT",
    supportedByProducts: ["returns"],
  },
  {
    name: "Republic of Congo",
    id: "cg",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "COG",
    supportedByProducts: ["returns"],
  },
  {
    name: "Romania",
    id: "ro",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ROU",
    supportedByProducts: ["returns"],
  },
  {
    name: "Russia",
    id: "ru",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "RUS",
    supportedByProducts: ["returns"],
  },
  {
    name: "Saudi Arabia",
    id: "sa",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "SAU",
  },
  {
    name: "Senegal",
    id: "sn",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SEN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Serbia",
    id: "rs",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SRB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Seychelles",
    id: "sc",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SYC",
    supportedByProducts: ["returns"],
  },
  {
    name: "Singapore",
    id: "sg",
    supportedByProducts: ["returns"],
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    alpha3code: "SGP",
  },
  {
    name: "Slovakia",
    id: "sk",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SVK",
    supportedByProducts: ["returns"],
  },
  {
    name: "Slovenia",
    id: "si",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SVN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Yemen",
    id: "ye",
    alpha3code: "YEM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Mayotte",
    id: "yt",
    alpha3code: "MYT",
  },
  {
    name: "South Africa",
    id: "za",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "ZAF",
  },
  {
    name: "South Korea",
    id: "kr",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "KOR",
  },
  {
    name: "Spain",
    id: "es",
    form: {
      fields: [
        { required: true, name: "tin" },
        { required: false, name: "name" },
      ],
    },
    alpha3code: "ESP",
    supportedByProducts: ["returns"],
  },
  {
    name: "Sweden",
    id: "se",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "SWE",
    supportedByProducts: ["returns"],
  },
  {
    name: "Switzerland",
    id: "ch",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "CHE",
  },
  {
    name: "Taiwan",
    id: "tw",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "TWN",
  },
  {
    name: "Thailand",
    id: "th",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "THA",
  },
  {
    name: "Turkey",
    id: "tr",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
        {
          required: true,

          name: "turkey_province",

          choices: [
            { id: "001", name: "ADANA" },
            { id: "002", name: "ADIYAMAN" },
            { id: "003", name: "AFYONKARAHİSAR" },
            { id: "004", name: "AĞRI" },
            { id: "068", name: "AKSARAY" },
            { id: "005", name: "AMASYA" },
            { id: "006", name: "ANKARA" },
            { id: "007", name: "ANTALYA" },
            { id: "075", name: "ARDAHAN" },
            { id: "008", name: "ARTVİN" },
            { id: "009", name: "AYDIN" },
            { id: "010", name: "BALIKESİR" },
            { id: "074", name: "BARTIN" },
            { id: "072", name: "BATMAN" },
            { id: "069", name: "BAYBURT" },
            { id: "011", name: "BİLECİK" },
            { id: "012", name: "BİNGÖL" },
            { id: "013", name: "BİTLİS" },
            { id: "014", name: "BOLU" },
            { id: "015", name: "BURDUR" },
            { id: "016", name: "BURSA" },
            { id: "017", name: "ÇANAKKALE" },
            { id: "018", name: "ÇANKIRI" },
            { id: "019", name: "ÇORUM" },
            { id: "020", name: "DENİZLİ" },
            { id: "021", name: "DİYARBAKIR" },
            { id: "081", name: "DÜZCE" },
            { id: "022", name: "EDİRNE" },
            { id: "023", name: "ELAZIĞ" },
            { id: "024", name: "ERZİNCAN" },
            { id: "025", name: "ERZURUM" },
            { id: "026", name: "ESKİŞEHİR" },
            { id: "027", name: "GAZİANTEP" },
            { id: "028", name: "GİRESUN" },
            { id: "029", name: "GÜMÜŞHANE" },
            { id: "030", name: "HAKKARİ" },
            { id: "031", name: "HATAY" },
            { id: "076", name: "IĞDIR" },
            { id: "032", name: "ISPARTA" },
            { id: "034", name: "İSTANBUL" },
            { id: "035", name: "İZMİR" },
            { id: "046", name: "KAHRAMANMARAŞ" },
            { id: "078", name: "KARABÜK" },
            { id: "070", name: "KARAMAN" },
            { id: "036", name: "KARS" },
            { id: "037", name: "KASTAMONU" },
            { id: "038", name: "KAYSERİ" },
            { id: "071", name: "KIRIKKALE" },
            { id: "039", name: "KIRKLARELİ" },
            { id: "040", name: "KIRŞEHİR" },
            { id: "079", name: "KİLİS" },
            { id: "041", name: "KOCAELİ" },
            { id: "042", name: "KONYA" },
            { id: "043", name: "KÜTAHYA" },
            { id: "044", name: "MALATYA" },
            { id: "045", name: "MANİSA" },
            { id: "047", name: "MARDİN" },
            { id: "033", name: "MERSİN" },
            { id: "048", name: "MUĞLA" },
            { id: "049", name: "MUŞ" },
            { id: "050", name: "NEVŞEHİR" },
            { id: "051", name: "NİĞDE" },
            { id: "052", name: "ORDU" },
            { id: "080", name: "OSMANİYE" },
            { id: "053", name: "RİZE" },
            { id: "054", name: "SAKARYA" },
            { id: "055", name: "SAMSUN" },
            { id: "056", name: "SİİRT" },
            { id: "057", name: "SİNOP" },
            { id: "058", name: "SİVAS" },
            { id: "063", name: "Ş.URFA" },
            { id: "073", name: "ŞIRNAK" },
            { id: "059", name: "TEKİRDAĞ" },
            { id: "060", name: "TOKAT" },
            { id: "061", name: "TRABZON" },
            { id: "062", name: "TUNCELİ" },
            { id: "064", name: "UŞAK" },
            { id: "065", name: "VAN" },
            { id: "077", name: "YALOVA" },
            { id: "066", name: "YOZGAT" },
            { id: "067", name: "ZONGULDAK" },
            { id: "999", name: "YURTDIŞI" },
          ],
        },
        {
          required: true,

          name: "turkey_tax_office",

          choices: [
            { id: "034276", name: "ŞİŞLİ VERGİ DAİRESİ" },
            { id: "035260", name: "ŞİRİNYER VERGİ DAİRESİ" },
            { id: "056110", name: "ŞİRVAN MAL MÜDÜRLÜĞÜ" },
            { id: "029103", name: "ŞİRAN MAL MÜDÜRLÜĞÜ" },
            { id: "034205", name: "ŞİLE VERGİ DAİRESİ" },
            { id: "031280", name: "ŞÜKRÜKANATLI VERGİ DAİRESİ" },
            { id: "003109", name: "ŞUHUT MAL MÜDÜRLÜĞÜ" },
            { id: "073260", name: "ŞIRNAK VERGİ DAİRESİ" },
            { id: "006207", name: "ŞEREFLİKOÇHİSAR VERGİ DAİRESİ" },
            { id: "037115", name: "ŞENPAZAR MAL MÜDÜRLÜĞÜ" },
            { id: "025111", name: "ŞENKAYA MAL MÜDÜRLÜĞÜ" },
            { id: "030103", name: "ŞEMDİNLİ MAL MÜDÜRLÜĞÜ" },
            { id: "063201", name: "ŞEHİTLİK VERGİ DAİRESİ" },
            { id: "033205", name: "ŞEHİTKERİM VERGİ DAİRESİ" },
            { id: "027252", name: "ŞEHİTKAMİL VERGİ DAİRESİ" },
            { id: "045251", name: "ŞEHİT CİHAN GÜNEŞ VERGİ DAİRESİ" },
            { id: "066106", name: "ŞEFAATLİ MAL MÜDÜRLÜĞÜ" },
            { id: "028108", name: "ŞEBİNKARAHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "008105", name: "ŞAVŞAT MAL MÜDÜRLÜĞÜ" },
            { id: "032107", name: "ŞARKİKARAAĞAÇ MAL MÜDÜRLÜĞÜ" },
            { id: "059266", name: "ŞARKÖY VERGİ DAİRESİ" },
            { id: "058260", name: "ŞARKIŞLA VERGİ DAİRESİ" },
            { id: "043110", name: "ŞAPHANE MAL MÜDÜRLÜĞÜ" },
            { id: "061112", name: "ŞALPAZARI MAL MÜDÜRLÜĞÜ" },
            { id: "027253", name: "ŞAHİNBEY VERGİ DAİRESİ" },
            { id: "018108", name: "ŞABANÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "016207", name: "İZNİK VERGİ DAİRESİ" },
            { id: "035250", name: "İZMİR İHTİSAS VERGİ DAİRESİ" },
            { id: "053111", name: "İYİDERE MAL MÜDÜRLÜĞÜ" },
            { id: "010111", name: "İVRİNDİ MAL MÜDÜRLÜĞÜ" },
            { id: "033250", name: "İSTİKLAL VERGİ DAİRESİ" },
            { id: "025105", name: "İSPİR MAL MÜDÜRLÜĞÜ" },
            { id: "027203", name: "İSLAHİYE VERGİ DAİRESİ" },
            { id: "019103", name: "İSKİLİP MAL MÜDÜRLÜĞÜ" },
            { id: "003264", name: "İSCEHİSAR VERGİ DAİRESİ" },
            { id: "022264", name: "İPSALA VERGİ DAİRESİ" },
            { id: "026109", name: "İNÖNÜ MAL MÜDÜRLÜĞÜ" },
            { id: "011107", name: "İNHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "016202", name: "İNEGÖL VERGİ DAİRESİ" },
            { id: "037108", name: "İNEBOLU MAL MÜDÜRLÜĞÜ" },
            { id: "009113", name: "İNCİRLİOVA MAL MÜDÜRLÜĞÜ" },
            { id: "038104", name: "İNCESU MAL MÜDÜRLÜĞÜ" },
            { id: "058105", name: "İMRANLI MAL MÜDÜRLÜĞÜ" },
            { id: "001115", name: "İMAMOĞLU MAL MÜDÜRLÜĞÜ" },
            { id: "024102", name: "İLİÇ MAL MÜDÜRLÜĞÜ" },
            { id: "041203", name: "İLYASBEY VERGİ DAİRESİ" },
            { id: "053105", name: "İKİZDERE MAL MÜDÜRLÜĞÜ" },
            { id: "052116", name: "İKİZCE MAL MÜDÜRLÜĞÜ" },
            { id: "034249", name: "İKİTELLİ VERGİ DAİRESİ" },
            { id: "003105", name: "İHSANİYE MAL MÜDÜRLÜĞÜ" },
            { id: "037113", name: "İHSANGAZİ MAL MÜDÜRLÜĞÜ" },
            { id: "073104", name: "İDİL MAL MÜDÜRLÜĞÜ" },
            { id: "007113", name: "İBRADI MAL MÜDÜRLÜĞÜ" },
            { id: "007251", name: "ÜÇKAPILAR VERGİ DAİRESİ" },
            { id: "024107", name: "ÜZÜMLÜ MAL MÜDÜRLÜĞÜ" },
            { id: "034277", name: "ÜSKÜDAR VERGİ DAİRESİ" },
            { id: "050106", name: "ÜRGÜP MAL MÜDÜRLÜĞÜ" },
            { id: "052262", name: "ÜNYE VERGİ DAİRESİ" },
            { id: "034252", name: "ÜMRANİYE VERGİ DAİRESİ" },
            { id: "038114", name: "ÖZVATAN MAL MÜDÜRLÜĞÜ" },
            { id: "065107", name: "ÖZALP MAL MÜDÜRLÜĞÜ" },
            { id: "047109", name: "ÖMERLİ MAL MÜDÜRLÜĞÜ" },
            { id: "035204", name: "ÖDEMİŞ VERGİ DAİRESİ" },
            { id: "035269", name: "ÇİĞLİ VERGİ DAİRESİ" },
            { id: "040101", name: "ÇİÇEKDAĞI MAL MÜDÜRLÜĞÜ" },
            { id: "020265", name: "ÇİVRİL VERGİ DAİRESİ" },
            { id: "043280", name: "ÇİNİLİ VERGİ DAİRESİ" },
            { id: "009262", name: "ÇİNE VERGİ DAİRESİ" },
            { id: "081103", name: "ÇİLİMLİ MAL MÜDÜRLÜĞÜ" },
            { id: "051107", name: "ÇİFTLİK MAL MÜDÜRLÜĞÜ" },
            { id: "021104", name: "ÇÜNGÜŞ MAL MÜDÜRLÜĞÜ" },
            { id: "042206", name: "ÇUMRA VERGİ DAİRESİ" },
            { id: "001255", name: "ÇUKUROVA VERGİ DAİRESİ" },
            { id: "030102", name: "ÇUKURCA MAL MÜDÜRLÜĞÜ" },
            { id: "006211", name: "ÇUBUK VERGİ DAİRESİ" },
            { id: "019260", name: "ÇORUM VERGİ DAİRESİ" },
            { id: "059250", name: "ÇORLU İHTİSAS VERGİ DAİRESİ" },
            { id: "059262", name: "ÇORLU VERGİ DAİRESİ" },
            { id: "003114", name: "ÇOBANLAR MAL MÜDÜRLÜĞÜ" },
            { id: "077103", name: "ÇINARCIK MAL MÜDÜRLÜĞÜ" },
            { id: "020202", name: "ÇINAR VERGİ DAİRESİ" },
            { id: "021103", name: "ÇINAR MAL MÜDÜRLÜĞÜ" },
            { id: "075101", name: "ÇILDIR MAL MÜDÜRLÜĞÜ" },
            { id: "035212", name: "ÇEŞME VERGİ DAİRESİ" },
            { id: "021102", name: "ÇERMİK MAL MÜDÜRLÜĞÜ" },
            { id: "018101", name: "ÇERKEŞ MAL MÜDÜRLÜĞÜ" },
            { id: "059261", name: "ÇERKEZKÖY VERGİ DAİRESİ" },
            { id: "062101", name: "ÇEMİŞGEZEK MAL MÜDÜRLÜĞÜ" },
            { id: "002102", name: "ÇELİKHAN MAL MÜDÜRLÜĞÜ" },
            { id: "015110", name: "ÇELTİKÇİ MAL MÜDÜRLÜĞÜ" },
            { id: "042125", name: "ÇELTİK MAL MÜDÜRLÜĞÜ" },
            { id: "071106", name: "ÇELEBİ MAL MÜDÜRLÜĞÜ" },
            { id: "016253", name: "ÇEKİRGE VERGİ DAİRESİ" },
            { id: "066104", name: "ÇEKEREK MAL MÜDÜRLÜĞÜ" },
            { id: "046107", name: "ÇAĞLIYANCERİT MAL MÜDÜRLÜĞÜ" },
            { id: "061104", name: "ÇAYKARA MAL MÜDÜRLÜĞÜ" },
            { id: "024101", name: "ÇAYIRLI MAL MÜDÜRLÜĞÜ" },
            { id: "066103", name: "ÇAYIRALAN MAL MÜDÜRLÜĞÜ" },
            { id: "053261", name: "ÇAYELİ VERGİ DAİRESİ" },
            { id: "067263", name: "ÇAYCUMA VERGİ DAİRESİ" },
            { id: "052115", name: "ÇAYBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "003202", name: "ÇAY VERGİ DAİRESİ" },
            { id: "015109", name: "ÇAVDIR MAL MÜDÜRLÜĞÜ" },
            { id: "043111", name: "ÇAVDARHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "037105", name: "ÇATALZEYTİN MAL MÜDÜRLÜĞÜ" },
            { id: "052114", name: "ÇATALPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "065102", name: "ÇATAK MAL MÜDÜRLÜĞÜ" },
            { id: "025102", name: "ÇAT MAL MÜDÜRLÜĞÜ" },
            { id: "061113", name: "ÇARŞIBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "055203", name: "ÇARŞAMBA VERGİ DAİRESİ" },
            { id: "020105", name: "ÇARDAK MAL MÜDÜRLÜĞÜ" },
            { id: "018260", name: "ÇANKIRI VERGİ DAİRESİ" },
            { id: "006257", name: "ÇANKAYA VERGİ DAİRESİ" },
            { id: "066110", name: "ÇANDIR MAL MÜDÜRLÜĞÜ" },
            { id: "028112", name: "ÇANAKÇI MAL MÜDÜRLÜĞÜ" },
            { id: "017260", name: "ÇANAKKALE VERGİ DAİRESİ" },
            { id: "017262", name: "ÇAN VERGİ DAİRESİ" },
            { id: "028114", name: "ÇAMOLUK MAL MÜDÜRLÜĞÜ" },
            { id: "033109", name: "ÇAMLIYAYLA MAL MÜDÜRLÜĞÜ" },
            { id: "053102", name: "ÇAMLIHEMŞİN MAL MÜDÜRLÜĞÜ" },
            { id: "006106", name: "ÇAMLIDERE MAL MÜDÜRLÜĞÜ" },
            { id: "034222", name: "ÇAMLICA İHTİSAS VERGİ DAİRESİ" },
            { id: "020104", name: "ÇAMELİ MAL MÜDÜRLÜĞÜ" },
            { id: "052113", name: "ÇAMAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "051103", name: "ÇAMARDI MAL MÜDÜRLÜĞÜ" },
            { id: "065109", name: "ÇALDIRAN MAL MÜDÜRLÜĞÜ" },
            { id: "020264", name: "ÇAL VERGİ DAİRESİ" },
            { id: "035270", name: "ÇAKABEY İHTİSAS VERGİ DAİRESİ" },
            { id: "001254", name: "ZİYAPAŞA VERGİ DAİRESİ" },
            { id: "034248", name: "ZİNCİRLİKUYU VERGİ DAİRESİ" },
            { id: "060264", name: "ZİLE VERGİ DAİRESİ" },
            { id: "034280", name: "ZEYTİNBURNU VERGİ DAİRESİ" },
            { id: "058111", name: "ZARA MAL MÜDÜRLÜĞÜ" },
            { id: "055290", name: "ZAFER VERGİ DAİRESİ" },
            { id: "001252", name: "YÜREĞİR VERGİ DAİRESİ" },
            { id: "030261", name: "YÜKSEKOVA VERGİ DAİRESİ" },
            { id: "008106", name: "YUSUFELİ MAL MÜDÜRLÜĞÜ" },
            { id: "042117", name: "YUNAK MAL MÜDÜRLÜĞÜ" },
            { id: "001112", name: "YUMURTALIK MAL MÜDÜRLÜĞÜ" },
            { id: "066260", name: "YOZGAT VERGİ DAİRESİ" },
            { id: "061110", name: "YOMRA MAL MÜDÜRLÜĞÜ" },
            { id: "081107", name: "YIĞILCA MAL MÜDÜRLÜĞÜ" },
            { id: "058110", name: "YILDIZELİ MAL MÜDÜRLÜĞÜ" },
            { id: "006261", name: "YILDIRIMBEYAZIT VERGİ DAİRESİ" },
            { id: "016252", name: "YILDIRIM VERGİ DAİRESİ" },
            { id: "053260", name: "YEŞİLÇAY VERGİ DAİRESİ" },
            { id: "044108", name: "YEŞİLYURT MAL MÜDÜRLÜĞÜ" },
            { id: "060109", name: "YEŞİLYURT MAL MÜDÜRLÜĞÜ" },
            { id: "015105", name: "YEŞİLOVA MAL MÜDÜRLÜĞÜ" },
            { id: "047113", name: "YEŞİLLİ MAL MÜDÜRLÜĞÜ" },
            { id: "038110", name: "YEŞİLHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "016256", name: "YEŞİL VERGİ DAİRESİ" },
            { id: "006266", name: "YEĞENBEY VERGİ DAİRESİ" },
            { id: "066263", name: "YERKÖY VERGİ DAİRESİ" },
            { id: "016208", name: "YENİŞEHİR VERGİ DAİRESİ" },
            { id: "032112", name: "YENİŞARBADEMLİ M MAL MÜDÜRLÜĞÜ" },
            { id: "014114", name: "YENİÇAĞA MAL MÜDÜRLÜĞÜ" },
            { id: "009111", name: "YENİPAZAR MAL MÜDÜRLÜĞÜ" },
            { id: "011106", name: "YENİPAZAR MAL MÜDÜRLÜĞÜ" },
            { id: "006255", name: "YENİMAHALLE VERGİ DAİRESİ" },
            { id: "034224", name: "YENİKAPI VERGİ DAİRESİ" },
            { id: "066113", name: "YENİFAKILI MAL MÜDÜRLÜĞÜ" },
            { id: "017111", name: "YENİCE MAL MÜDÜRLÜĞÜ" },
            { id: "078105", name: "YENİCE MAL MÜDÜRLÜĞÜ" },
            { id: "034293", name: "YENİ BOSNA VERGİ DAİRESİ" },
            { id: "034253", name: "YEDİTEPE VERGİ DAİRESİ" },
            { id: "012107", name: "YEDİSU MAL MÜDÜRLÜĞÜ" },
            { id: "028111", name: "YAĞLIDERE MAL MÜDÜRLÜĞÜ" },
            { id: "044113", name: "YAZIHAN MAL MÜDÜRLÜĞÜ" },
            { id: "012106", name: "YAYLADERE MAL MÜDÜRLÜĞÜ" },
            { id: "031108", name: "YAYLADAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "027106", name: "YAVUZELİ MAL MÜDÜRLÜĞÜ" },
            { id: "048266", name: "YATAĞAN VERGİ DAİRESİ" },
            { id: "018109", name: "YAPRAKLI MAL MÜDÜRLÜĞÜ" },
            { id: "035252", name: "YAMANLAR VERGİ DAİRESİ" },
            { id: "032262", name: "YALVAÇ VERGİ DAİRESİ" },
            { id: "077201", name: "YALOVA VERGİ DAİRESİ" },
            { id: "042131", name: "YALIHÜYÜK MAL MÜDÜRLÜĞÜ" },
            { id: "055114", name: "YAKAKENT MAL MÜDÜRLÜĞÜ" },
            { id: "034292", name: "YAKACIK VERGİ DAİRESİ" },
            { id: "038109", name: "YAHYALI MAL MÜDÜRLÜĞÜ" },
            { id: "006269", name: "Y.GALİP VERGİ DAİRESİ" },
            { id: "039107", name: "VİZE MAL MÜDÜRLÜĞÜ" },
            { id: "063261", name: "VİRANŞEHİR VERGİ DAİRESİ" },
            { id: "055108", name: "VEZİRKÖPRÜ MAL MÜDÜRLÜĞÜ" },
            { id: "006252", name: "VERASET VE HARÇ VERGİ DAİRESİ" },
            { id: "034221", name: "VATAN İHTİSAS VERGİ DAİRESİ" },
            { id: "049103", name: "VARTO MAL MÜDÜRLÜĞÜ" },
            { id: "065260", name: "VAN VERGİ DAİRESİ" },
            { id: "061262", name: "VAKFIKEBİR VERGİ DAİRESİ" },
            { id: "064260", name: "UŞAK VERGİ DAİRESİ" },
            { id: "019110", name: "UĞURLUDAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "067201", name: "UZUNMEHMET VERGİ DAİRESİ" },
            { id: "022262", name: "UZUNKÖPRÜ VERGİ DAİRESİ" },
            { id: "025115", name: "UZUNDERE MAL MÜDÜRLÜĞÜ" },
            { id: "035208", name: "URLA VERGİ DAİRESİ" },
            { id: "033252", name: "URAY VERGİ DAİRESİ" },
            { id: "041202", name: "ULUÇINAR VERGİ DAİRESİ" },
            { id: "006260", name: "ULUS VERGİ DAİRESİ" },
            { id: "074103", name: "ULUS MAL MÜDÜRLÜĞÜ" },
            { id: "051105", name: "ULUKIŞLA MAL MÜDÜRLÜĞÜ" },
            { id: "073106", name: "ULUDERE MAL MÜDÜRLÜĞÜ" },
            { id: "016255", name: "ULUDAĞ VERGİ DAİRESİ" },
            { id: "032108", name: "ULUBORLU MAL MÜDÜRLÜĞÜ" },
            { id: "052109", name: "ULUBEY MAL MÜDÜRLÜĞÜ" },
            { id: "064104", name: "ULUBEY MAL MÜDÜRLÜĞÜ" },
            { id: "058116", name: "ULAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "048108", name: "ULA MAL MÜDÜRLÜĞÜ" },
            { id: "028109", name: "TİREBOLU MAL MÜDÜRLÜĞÜ" },
            { id: "035205", name: "TİRE VERGİ DAİRESİ" },
            { id: "046106", name: "TÜRKOĞLU MAL MÜDÜRLÜĞÜ" },
            { id: "057106", name: "TÜRKELİ MAL MÜDÜRLÜĞÜ" },
            { id: "042130", name: "TUZLUKCU MAL MÜDÜRLÜĞÜ" },
            { id: "076103", name: "TUZLUCA MAL MÜDÜRLÜĞÜ" },
            { id: "034245", name: "TUZLA VERGİ DAİRESİ" },
            { id: "004107", name: "TUTAK MAL MÜDÜRLÜĞÜ" },
            { id: "002108", name: "TUT MAL MÜDÜRLÜĞÜ" },
            { id: "060263", name: "TURHAL VERGİ DAİRESİ" },
            { id: "045209", name: "TURGUTLU VERGİ DAİRESİ" },
            { id: "062260", name: "TUNCELİ VERGİ DAİRESİ" },
            { id: "034257", name: "TUNA VERGİ DAİRESİ" },
            { id: "001111", name: "TUFANBEYLİ MAL MÜDÜRLÜĞÜ" },
            { id: "037261", name: "TOSYA VERGİ DAİRESİ" },
            { id: "029104", name: "TORUL MAL MÜDÜRLÜĞÜ" },
            { id: "025113", name: "TORTUM MAL MÜDÜRLÜĞÜ" },
            { id: "033255", name: "TOROS VERGİ DAİRESİ" },
            { id: "035206", name: "TORBALI VERGİ DAİRESİ" },
            { id: "063280", name: "TOPÇUMEYDANI VERGİ DAİRESİ" },
            { id: "080106", name: "TOPRAKKALE MAL MÜDÜRLÜĞÜ" },
            { id: "061108", name: "TONYA MAL MÜDÜRLÜĞÜ" },
            { id: "038108", name: "TOMARZA MAL MÜDÜRLÜĞÜ" },
            { id: "060260", name: "TOKAT VERGİ DAİRESİ" },
            { id: "003201", name: "TINAZTEPE VERGİ DAİRESİ" },
            { id: "055204", name: "TERME VERGİ DAİRESİ" },
            { id: "024106", name: "TERCAN MAL MÜDÜRLÜĞÜ" },
            { id: "041252", name: "TEPECİK VERGİ DAİRESİ" },
            { id: "025112", name: "TEKMAN MAL MÜDÜRLÜĞÜ" },
            { id: "055112", name: "TEKKEKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "015104", name: "TEFENNİ MAL MÜDÜRLÜĞÜ" },
            { id: "005263", name: "TAŞOVA VERGİ DAİRESİ" },
            { id: "004106", name: "TAŞLIÇAY MAL MÜDÜRLÜĞÜ" },
            { id: "037262", name: "TAŞKÖPRÜ VERGİ DAİRESİ" },
            { id: "042123", name: "TAŞKENT MAL MÜDÜRLÜĞÜ" },
            { id: "035262", name: "TAŞITLAR VERGİ DAİRESİ" },
            { id: "043262", name: "TAVŞANLI VERGİ DAİRESİ" },
            { id: "020262", name: "TAVAS VERGİ DAİRESİ" },
            { id: "013261", name: "TATVAN VERGİ DAİRESİ" },
            { id: "054109", name: "TARAKLI MAL MÜDÜRLÜĞÜ" },
            { id: "056260", name: "SİİRT VERGİ DAİRESİ" },
            { id: "023107", name: "SİVRİCE MAL MÜDÜRLÜĞÜ" },
            { id: "063260", name: "SİVEREK VERGİ DAİRESİ" },
            { id: "064105", name: "SİVASLI MAL MÜDÜRLÜĞÜ" },
            { id: "058280", name: "SİTE VERGİ DAİRESİ" },
            { id: "057260", name: "SİNOP VERGİ DAİRESİ" },
            { id: "002107", name: "SİNCİK MAL MÜDÜRLÜĞÜ" },
            { id: "006273", name: "SİNCAN VERGİ DAİRESİ" },
            { id: "003107", name: "SİNANPAŞA MAL MÜDÜRLÜĞÜ" },
            { id: "043261", name: "SİMAV VERGİ DAİRESİ" },
            { id: "034203", name: "SİLİVRİ VERGİ DAİRESİ" },
            { id: "033202", name: "SİLİFKE VERGİ DAİRESİ" },
            { id: "021111", name: "SİLVAN MAL MÜDÜRLÜĞÜ" },
            { id: "073262", name: "SİLOPİ VERGİ DAİRESİ" },
            { id: "032106", name: "SÜTÇÜLER MAL MÜDÜRLÜĞÜ" },
            { id: "061107", name: "SÜRMENE MAL MÜDÜRLÜĞÜ" },
            { id: "022108", name: "SÜLOĞLU MAL MÜDÜRLÜĞÜ" },
            { id: "059201", name: "SÜLEYMANPAŞA VERGİ DAİRESİ" },
            { id: "021281", name: "SÜLEYMANNAZİF VERGİ DAİRESİ" },
            { id: "011105", name: "SÖĞÜT MAL MÜDÜRLÜĞÜ" },
            { id: "009261", name: "SÖKE VERGİ DAİRESİ" },
            { id: "058109", name: "SUŞEHRİ MAL MÜDÜRLÜĞÜ" },
            { id: "036113", name: "SUSUZ MAL MÜDÜRLÜĞÜ" },
            { id: "010265", name: "SUSURLUK VERGİ DAİRESİ" },
            { id: "063263", name: "SURUÇ VERGİ DAİRESİ" },
            { id: "019261", name: "SUNGURLU VERGİ DAİRESİ" },
            { id: "080105", name: "SUMBAS MAL MÜDÜRLÜĞÜ" },
            { id: "060111", name: "SULUSARAY MAL MÜDÜRLÜĞÜ" },
            { id: "005264", name: "SULUOVA VERGİ DAİRESİ" },
            { id: "009110", name: "SULTANHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "003108", name: "SULTANDAĞI MAL MÜDÜRLÜĞÜ" },
            { id: "034242", name: "SULTANBEYLİ VERGİ DAİRESİ" },
            { id: "071103", name: "SULAKYURT MAL MÜDÜRLÜĞÜ" },
            { id: "027251", name: "SUBURCU VERGİ DAİRESİ" },
            { id: "066262", name: "SORGUN VERGİ DAİRESİ" },
            { id: "045208", name: "SOMA VERGİ DAİRESİ" },
            { id: "012104", name: "SOLHAN MAL MÜDÜRLÜĞÜ" },
            { id: "010268", name: "SINDIRGI VERGİ DAİRESİ" },
            { id: "006262", name: "SEĞMENLER VERGİ DAİRESİ" },
            { id: "026105", name: "SEYİTGAZİ MAL MÜDÜRLÜĞÜ" },
            { id: "001253", name: "SEYHAN VERGİ DAİRESİ" },
            { id: "042207", name: "SEYDİŞEHİR VERGİ DAİRESİ" },
            { id: "037119", name: "SEYDİLER MAL MÜDÜRLÜĞÜ" },
            { id: "048113", name: "SEYDİKEMER MAL MÜDÜRLÜĞÜ" },
            { id: "016254", name: "SETBAŞI VERGİ DAİRESİ" },
            { id: "020114", name: "SERİNHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "007202", name: "SERİK VERGİ DAİRESİ" },
            { id: "032105", name: "SENİRKENT MAL MÜDÜRLÜĞÜ" },
            { id: "036112", name: "SELİM MAL MÜDÜRLÜĞÜ" },
            { id: "035209", name: "SELÇUK VERGİ DAİRESİ" },
            { id: "042251", name: "SELÇUK VERGİ DAİRESİ" },
            { id: "045110", name: "SELENDİ MAL MÜDÜRLÜĞÜ" },
            { id: "035114", name: "SEFERİHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "014108", name: "SEBEN MAL MÜDÜRLÜĞÜ" },
            { id: "001110", name: "SAİMBEYLİ MAL MÜDÜRLÜĞÜ" },
            { id: "047110", name: "SAVUR MAL MÜDÜRLÜĞÜ" },
            { id: "010114", name: "SAVAŞTEPE MAL MÜDÜRLÜĞÜ" },
            { id: "072105", name: "SASON MAL MÜDÜRLÜĞÜ" },
            { id: "045207", name: "SARUHANLI VERGİ DAİRESİ" },
            { id: "038107", name: "SARIZ MAL MÜDÜRLÜĞÜ" },
            { id: "034283", name: "SARIYER VERGİ DAİRESİ" },
            { id: "068104", name: "SARIYAHŞİ MAL MÜDÜRLÜĞÜ" },
            { id: "070105", name: "SARIVELİLER MAL MÜDÜRLÜĞÜ" },
            { id: "038106", name: "SARIOĞLAN MAL MÜDÜRLÜĞÜ" },
            { id: "066105", name: "SARIKAYA MAL MÜDÜRLÜĞÜ" },
            { id: "036111", name: "SARIKAMIŞ MAL MÜDÜRLÜĞÜ" },
            { id: "045206", name: "SARIGÖL VERGİ DAİRESİ" },
            { id: "034288", name: "SARIGAZİ VERGİ DAİRESİ" },
            { id: "026104", name: "SARICAKAYA MAL MÜDÜRLÜĞÜ" },
            { id: "042115", name: "SARAYÖNÜ MAL MÜDÜRLÜĞÜ" },
            { id: "020201", name: "SARAYLAR VERGİ DAİRESİ" },
            { id: "020260", name: "SARAYKÖY VERGİ DAİRESİ" },
            { id: "066112", name: "SARAYKENT MAL MÜDÜRLÜĞÜ" },
            { id: "057108", name: "SARAYDÜZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "059106", name: "SARAY MAL MÜDÜRLÜĞÜ" },
            { id: "065111", name: "SARAY MAL MÜDÜRLÜĞÜ" },
            { id: "054253", name: "SAPANCA VERGİ DAİRESİ" },
            { id: "003263", name: "SANDIKLI VERGİ DAİRESİ" },
            { id: "002106", name: "SAMSAT MAL MÜDÜRLÜĞÜ" },
            { id: "031263", name: "SAMANDAĞ VERGİ DAİRESİ" },
            { id: "045205", name: "SALİHLİ ADİL ORAL VERGİ DAİRESİ" },
            { id: "055111", name: "SALIPAZARI MAL MÜDÜRLÜĞÜ" },
            { id: "054250", name: "SAKARYA İHTİSAS VERGİ DAİRESİ" },
            { id: "031202", name: "SAHİL VERGİ DAİRESİ" },
            { id: "078260", name: "SAFRANBOLU VERGİ DAİRESİ" },
            { id: "034258", name: "RIHTIM VERGİ DAİRESİ" },
            { id: "060105", name: "REŞADİYE MAL MÜDÜRLÜĞÜ" },
            { id: "031262", name: "REYHANLI VERGİ DAİRESİ" },
            { id: "024105", name: "REFAHİYE MAL MÜDÜRLÜĞÜ" },
            { id: "028110", name: "PİRAZİZ MAL MÜDÜRLÜĞÜ" },
            { id: "062107", name: "PÜLÜMÜR MAL MÜDÜRLÜĞÜ" },
            { id: "044107", name: "PÖTÜRGE MAL MÜDÜRLÜĞÜ" },
            { id: "001109", name: "POZANTI MAL MÜDÜRLÜĞÜ" },
            { id: "075105", name: "POSOF MAL MÜDÜRLÜĞÜ" },
            { id: "006205", name: "POLATLI VERGİ DAİRESİ" },
            { id: "039106", name: "PINARHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "038202", name: "PINARBAŞI VERGİ DAİRESİ" },
            { id: "037114", name: "PINARBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "052108", name: "PERŞEMBE MAL MÜDÜRLÜĞÜ" },
            { id: "056107", name: "PERVARİ MAL MÜDÜRLÜĞÜ" },
            { id: "062106", name: "PERTEK MAL MÜDÜRLÜĞÜ" },
            { id: "034263", name: "PENDİK VERGİ DAİRESİ" },
            { id: "039105", name: "PEHLİVANKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "025116", name: "PAZARYOLU MAL MÜDÜRLÜĞÜ" },
            { id: "011104", name: "PAZARYERİ MAL MÜDÜRLÜĞÜ" },
            { id: "043112", name: "PAZARLAR MAL MÜDÜRLÜĞÜ" },
            { id: "046262", name: "PAZARCIK VERGİ DAİRESİ" },
            { id: "053262", name: "PAZAR VERGİ DAİRESİ" },
            { id: "060108", name: "PAZAR MAL MÜDÜRLÜĞÜ" },
            { id: "004262", name: "PATNOS VERGİ DAİRESİ" },
            { id: "025110", name: "PASİNLER MAL MÜDÜRLÜĞÜ" },
            { id: "054108", name: "PAMUKOVA MAL MÜDÜRLÜĞÜ" },
            { id: "020280", name: "PAMUKKALE VERGİ DAİRESİ" },
            { id: "020115", name: "PAMUKKALE MAL MÜDÜRLÜĞÜ" },
            { id: "023106", name: "PALU MAL MÜDÜRLÜĞÜ" },
            { id: "019112", name: "OĞUZLAR MAL MÜDÜRLÜĞÜ" },
            { id: "027105", name: "OĞUZELİ MAL MÜDÜRLÜĞÜ" },
            { id: "062105", name: "OVACIK MAL MÜDÜRLÜĞÜ" },
            { id: "078103", name: "OVACIK MAL MÜDÜRLÜĞÜ" },
            { id: "024108", name: "OTLUKBELİ MAL MÜDÜRLÜĞÜ" },
            { id: "006271", name: "OSTİM VERGİ DAİRESİ" },
            { id: "080201", name: "OSMANİYE VERGİ DAİRESİ" },
            { id: "016251", name: "OSMANGAZİ VERGİ DAİRESİ" },
            { id: "011103", name: "OSMANELİ MAL MÜDÜRLÜĞÜ" },
            { id: "019107", name: "OSMANCIK MAL MÜDÜRLÜĞÜ" },
            { id: "019106", name: "ORTAKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "068103", name: "ORTAKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "048110", name: "ORTACA MAL MÜDÜRLÜĞÜ" },
            { id: "018106", name: "ORTA MAL MÜDÜRLÜĞÜ" },
            { id: "016206", name: "ORHANGAZİ VERGİ DAİRESİ" },
            { id: "016108", name: "ORHANELİ MAL MÜDÜRLÜĞÜ" },
            { id: "025109", name: "OLUR MAL MÜDÜRLÜĞÜ" },
            { id: "025108", name: "OLTU MAL MÜDÜRLÜĞÜ" },
            { id: "061261", name: "OF VERGİ DAİRESİ" },
            { id: "051260", name: "NİĞDE VERGİ DAİRESİ" },
            { id: "027202", name: "NİZİP VERGİ DAİRESİ" },
            { id: "016257", name: "NİLÜFER VERGİ DAİRESİ" },
            { id: "060262", name: "NİKSAR VERGİ DAİRESİ" },
            { id: "047262", name: "NUSAYBİN VERGİ DAİRESİ" },
            { id: "046109", name: "NURHAK MAL MÜDÜRLÜĞÜ" },
            { id: "027108", name: "NURDAĞI MAL MÜDÜRLÜĞÜ" },
            { id: "050260", name: "NEVŞEHİR VERGİ DAİRESİ" },
            { id: "062104", name: "NAZİMİYE MAL MÜDÜRLÜĞÜ" },
            { id: "009260", name: "NAZİLLİ VERGİ DAİRESİ" },
            { id: "025107", name: "NARMAN MAL MÜDÜRLÜĞÜ" },
            { id: "059260", name: "NAMIK KEMAL VERGİ DAİRESİ" },
            { id: "006117", name: "NALLIHAN MAL MÜDÜRLÜĞÜ" },
            { id: "034287", name: "NAKİL VASITA. VERGİ DAİRESİ" },
            { id: "006259", name: "MİTHATPAŞA VERGİ DAİRESİ" },
            { id: "038251", name: "MİMARSİNAN VERGİ DAİRESİ" },
            { id: "048264", name: "MİLAS VERGİ DAİRESİ" },
            { id: "026103", name: "MİHALIÇÇIK MAL MÜDÜRLÜĞÜ" },
            { id: "026112", name: "MİHALGAZİ MAL MÜDÜRLÜĞÜ" },
            { id: "047107", name: "MİDYAT MAL MÜDÜRLÜĞÜ" },
            { id: "049260", name: "MUŞ VERGİ DAİRESİ" },
            { id: "048260", name: "MUĞLA VERGİ DAİRESİ" },
            { id: "013104", name: "MUTKİ MAL MÜDÜRLÜĞÜ" },
            { id: "033104", name: "MUT MAL MÜDÜRLÜĞÜ" },
            { id: "016204", name: "MUSTAFAKEMALPAŞA VERGİ DAİRESİ" },
            { id: "008107", name: "MURGUL MAL MÜDÜRLÜĞÜ" },
            { id: "007253", name: "MURATPAŞA VERGİ DAİRESİ" },
            { id: "059265", name: "MURATLI VERGİ DAİRESİ" },
            { id: "065106", name: "MURADİYE MAL MÜDÜRLÜĞÜ" },
            { id: "014107", name: "MUDURNU MAL MÜDÜRLÜĞÜ" },
            { id: "016205", name: "MUDANYA VERGİ DAİRESİ" },
            { id: "040103", name: "MUCUR MAL MÜDÜRLÜĞÜ" },
            { id: "042252", name: "MEVLANA VERGİ DAİRESİ" },
            { id: "045252", name: "MESİR VERGİ DAİRESİ" },
            { id: "052107", name: "MESUDİYE MAL MÜDÜRLÜĞÜ" },
            { id: "022106", name: "MERİÇ MAL MÜDÜRLÜĞÜ" },
            { id: "005261", name: "MERZİFON VERGİ DAİRESİ" },
            { id: "034239", name: "MERTER VERGİ DAİRESİ" },
            { id: "033256", name: "MERSİN İHTİSAS VERGİ DAİRESİ" },
            { id: "042253", name: "MERAM VERGİ DAİRESİ" },
            { id: "014106", name: "MENGEN MAL MÜDÜRLÜĞÜ" },
            { id: "035203", name: "MENEMEN VERGİ DAİRESİ" },
            { id: "035215", name: "MENDERES VERGİ DAİRESİ" },
            { id: "019105", name: "MECİTÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "034274", name: "MECİDİYEKÖY VERGİ DAİRESİ" },
            { id: "061105", name: "MAÇKA MAL MÜDÜRLÜĞÜ" },
            { id: "047106", name: "MAZIDAĞI MAL MÜDÜRLÜĞÜ" },
            { id: "062103", name: "MAZGİRT MAL MÜDÜRLÜĞÜ" },
            { id: "034247", name: "MASLAK VERGİ DAİRESİ" },
            { id: "048265", name: "MARMARİS VERGİ DAİRESİ" },
            { id: "010117", name: "MARMARA MAL MÜDÜRLÜĞÜ" },
            { id: "034232", name: "MARMARA KUR. VERGİ DAİRESİ" },
            { id: "059108", name: "MARM. EREĞLİSİ MAL MÜDÜRLÜĞÜ" },
            { id: "047260", name: "MARDİN VERGİ DAİRESİ" },
            { id: "045250", name: "MANİSA İHTİSAS VERGİ DAİRESİ" },
            { id: "010113", name: "MANYAS MAL MÜDÜRLÜĞÜ" },
            { id: "007203", name: "MANAVGAT VERGİ DAİRESİ" },
            { id: "006254", name: "MALTEPE VERGİ DAİRESİ" },
            { id: "059264", name: "MALKARA VERGİ DAİRESİ" },
            { id: "049102", name: "MALAZGİRT MAL MÜDÜRLÜĞÜ" },
            { id: "026102", name: "MAHMUDİYE MAL MÜDÜRLÜĞÜ" },
            { id: "023105", name: "MADEN MAL MÜDÜRLÜĞÜ" },
            { id: "006270", name: "M.KARAGÜZEL VERGİ DAİRESİ" },
            { id: "033254", name: "LİMAN VERGİ DAİRESİ" },
            { id: "021110", name: "LİCE MAL MÜDÜRLÜĞÜ" },
            { id: "039261", name: "LÜLEBURGAZ VERGİ DAİRESİ" },
            { id: "019113", name: "LAÇİN MAL MÜDÜRLÜĞÜ" },
            { id: "017110", name: "LAPSEKİ MAL MÜDÜRLÜĞÜ" },
            { id: "022105", name: "LALAPAŞA MAL MÜDÜRLÜĞÜ" },
            { id: "055106", name: "LADİK MAL MÜDÜRLÜĞÜ" },
            { id: "035211", name: "KİRAZ VERGİ DAİRESİ" },
            { id: "079201", name: "KİLİS VERGİ DAİRESİ" },
            { id: "034296", name: "KÜÇÜKÇEKMECE VERGİ DAİRESİ" },
            { id: "034262", name: "KÜÇÜKYALI VERGİ DAİRESİ" },
            { id: "034237", name: "KÜÇÜKKÖY VERGİ DAİRESİ" },
            { id: "029108", name: "KÜRTÜN MAL MÜDÜRLÜĞÜ" },
            { id: "037109", name: "KÜRE MAL MÜDÜRLÜĞÜ" },
            { id: "009115", name: "KÖŞK MAL MÜDÜRLÜĞÜ" },
            { id: "048263", name: "KÖYCEĞİZ VERGİ DAİRESİ" },
            { id: "029107", name: "KÖSE MAL MÜDÜRLÜĞÜ" },
            { id: "041206", name: "KÖRFEZ VERGİ DAİRESİ" },
            { id: "025118", name: "KÖPRÜKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "052260", name: "KÖPRÜBAŞI VERGİ DAİRESİ" },
            { id: "045115", name: "KÖPRÜBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "061117", name: "KÖPRÜBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "009265", name: "KUŞADASI VERGİ DAİRESİ" },
            { id: "009107", name: "KUYUCAK MAL MÜDÜRLÜĞÜ" },
            { id: "018105", name: "KURŞUNLU MAL MÜDÜRLÜĞÜ" },
            { id: "074102", name: "KURUCAŞİLE MAL MÜDÜRLÜĞÜ" },
            { id: "010280", name: "KURTDERELİ VERGİ DAİRESİ" },
            { id: "056106", name: "KURTALAN MAL MÜDÜRLÜĞÜ" },
            { id: "052106", name: "KUMRU MAL MÜDÜRLÜĞÜ" },
            { id: "007206", name: "KUMLUCA VERGİ DAİRESİ" },
            { id: "031111", name: "KUMLU MAL MÜDÜRLÜĞÜ" },
            { id: "044112", name: "KULUNCAK MAL MÜDÜRLÜĞÜ" },
            { id: "042209", name: "KULU VERGİ DAİRESİ" },
            { id: "021109", name: "KULP MAL MÜDÜRLÜĞÜ" },
            { id: "045211", name: "KULA VERGİ DAİRESİ" },
            { id: "009105", name: "KOÇARLI MAL MÜDÜRLÜĞÜ" },
            { id: "034246", name: "KOZYATAĞI VERGİ DAİRESİ" },
            { id: "072104", name: "KOZLUK MAL MÜDÜRLÜĞÜ" },
            { id: "027255", name: "KOZANLI VERGİ DAİRESİ" },
            { id: "001203", name: "KOZAN VERGİ DAİRESİ" },
            { id: "050105", name: "KOZAKLI MAL MÜDÜRLÜĞÜ" },
            { id: "058107", name: "KOYULHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "023109", name: "KOVANCILAR MAL MÜDÜRLÜĞÜ" },
            { id: "007257", name: "KORKUTELİ VERGİ DAİRESİ" },
            { id: "049105", name: "KORKUT MAL MÜDÜRLÜĞÜ" },
            { id: "018113", name: "KORGUN MAL MÜDÜRLÜĞÜ" },
            { id: "052105", name: "KORGAN MAL MÜDÜRLÜĞÜ" },
            { id: "035259", name: "KORDON VERGİ DAİRESİ" },
            { id: "042250", name: "KONYA İHTİSAS VERGİ DAİRESİ" },
            { id: "035258", name: "KONAK VERGİ DAİRESİ" },
            { id: "039103", name: "KOFCAZ MAL MÜDÜRLÜĞÜ" },
            { id: "003280", name: "KOCATEPE VERGİ DAİRESİ" },
            { id: "034260", name: "KOCASİNAN VERGİ DAİRESİ" },
            { id: "034273", name: "KOCAMUSTAFAPAŞA VERGİ DAİRESİ" },
            { id: "021113", name: "KOCAKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "041250", name: "KOCAELİ İHTİSAS VERGİ DAİRESİ" },
            { id: "054107", name: "KOCAALİ MAL MÜDÜRLÜĞÜ" },
            { id: "012103", name: "KIĞI MAL MÜDÜRLÜĞÜ" },
            { id: "003117", name: "KIZILÖREN MAL MÜDÜRLÜĞÜ" },
            { id: "047261", name: "KIZILTEPE VERGİ DAİRESİ" },
            { id: "033204", name: "KIZILMURAT VERGİ DAİRESİ" },
            { id: "018111", name: "KIZILIRMAK MAL MÜDÜRLÜĞÜ" },
            { id: "006115", name: "KIZILCAHAMAM MAL MÜDÜRLÜĞÜ" },
            { id: "006258", name: "KIZILBEY VERGİ DAİRESİ" },
            { id: "040260", name: "KIRŞEHİR VERGİ DAİRESİ" },
            { id: "022260", name: "KIRKPINAR VERGİ DAİRESİ" },
            { id: "039260", name: "KIRKLARELİ VERGİ DAİRESİ" },
            { id: "045204", name: "KIRKAĞAÇ VERGİ DAİRESİ" },
            { id: "031261", name: "KIRIKHAN VERGİ DAİRESİ" },
            { id: "035210", name: "KINIK VERGİ DAİRESİ" },
            { id: "014105", name: "KIBRISCIK MAL MÜDÜRLÜĞÜ" },
            { id: "028107", name: "KEŞAP MAL MÜDÜRLÜĞÜ" },
            { id: "022261", name: "KEŞAN VERGİ DAİRESİ" },
            { id: "006278", name: "KEÇİÖREN VERGİ DAİRESİ" },
            { id: "032104", name: "KEÇİBORLU MAL MÜDÜRLÜĞÜ" },
            { id: "071102", name: "KESKİN MAL MÜDÜRLÜĞÜ" },
            { id: "010112", name: "KEPSUT MAL MÜDÜRLÜĞÜ" },
            { id: "035257", name: "KEMERALTI VERGİ DAİRESİ" },
            { id: "007205", name: "KEMER VERGİ DAİRESİ" },
            { id: "015107", name: "KEMER MAL MÜDÜRLÜĞÜ" },
            { id: "024104", name: "KEMALİYE MAL MÜDÜRLÜĞÜ" },
            { id: "035207", name: "KEMALPAŞA VERGİ DAİRESİ" },
            { id: "024103", name: "KEMAH MAL MÜDÜRLÜĞÜ" },
            { id: "029102", name: "KELKİT MAL MÜDÜRLÜĞÜ" },
            { id: "016105", name: "KELES MAL MÜDÜRLÜĞÜ" },
            { id: "023104", name: "KEBAN MAL MÜDÜRLÜĞÜ" },
            { id: "007107", name: "KAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "036109", name: "KAĞIZMAN MAL MÜDÜRLÜĞÜ" },
            { id: "034279", name: "KAĞITHANE VERGİ DAİRESİ" },
            { id: "053201", name: "KAÇKAR VERGİ DAİRESİ" },
            { id: "025280", name: "KAZIMKARABEKİR VERGİ DAİRESİ" },
            { id: "038250", name: "KAYSERİ İHTİSAS VERGİ DAİRESİ" },
            { id: "081106", name: "KAYNAŞLI MAL MÜDÜRLÜĞÜ" },
            { id: "054105", name: "KAYNARCA MAL MÜDÜRLÜĞÜ" },
            { id: "032260", name: "KAYMAKKAPI VERGİ DAİRESİ" },
            { id: "006253", name: "KAVAKLIDERE VERGİ DAİRESİ" },
            { id: "048111", name: "KAVAKLIDERE MAL MÜDÜRLÜĞÜ" },
            { id: "055105", name: "KAVAK MAL MÜDÜRLÜĞÜ" },
            { id: "037260", name: "KASTAMONU VERGİ DAİRESİ" },
            { id: "034254", name: "KASIMPAŞA VERGİ DAİRESİ" },
            { id: "035256", name: "KARŞIYAKA VERGİ DAİRESİ" },
            { id: "034285", name: "KARTAL VERGİ DAİRESİ" },
            { id: "036260", name: "KARS VERGİ DAİRESİ" },
            { id: "009114", name: "KARPUZLU MAL MÜDÜRLÜĞÜ" },
            { id: "012102", name: "KARLIOVA MAL MÜDÜRLÜĞÜ" },
            { id: "027107", name: "KARKAMIŞ MAL MÜDÜRLÜĞÜ" },
            { id: "019104", name: "KARGI MAL MÜDÜRLÜĞÜ" },
            { id: "010201", name: "KARESİ VERGİ DAİRESİ" },
            { id: "001105", name: "KARAİSALI MAL MÜDÜRLÜĞÜ" },
            { id: "025114", name: "KARAÇOBAN MAL MÜDÜRLÜĞÜ" },
            { id: "025106", name: "KARAYAZI MAL MÜDÜRLÜĞÜ" },
            { id: "001205", name: "KARATAŞ VERGİ DAİRESİ" },
            { id: "054204", name: "KARASU VERGİ DAİRESİ" },
            { id: "054111", name: "KARAPÜRÇEK MAL MÜDÜRLÜĞÜ" },
            { id: "042210", name: "KARAPINAR VERGİ DAİRESİ" },
            { id: "041205", name: "KARAMÜRSEL VERGİ DAİRESİ" },
            { id: "015106", name: "KARAMANLI MAL MÜDÜRLÜĞÜ" },
            { id: "070201", name: "KARAMAN VERGİ DAİRESİ" },
            { id: "023103", name: "KARAKOÇAN MAL MÜDÜRLÜĞÜ" },
            { id: "076102", name: "KARAKOYUNLU MAL MÜDÜRLÜĞÜ" },
            { id: "071107", name: "KARAKEÇİLİ MAL MÜDÜRLÜĞÜ" },
            { id: "064103", name: "KARAHALLI MAL MÜDÜRLÜĞÜ" },
            { id: "067280", name: "KARAELMAS VERGİ DAİRESİ" },
            { id: "061280", name: "KARADENİZ VERGİ DAİRESİ" },
            { id: "067261", name: "KARADENİZ EREĞLİ VERGİ DAİRESİ" },
            { id: "009104", name: "KARACASU MAL MÜDÜRLÜĞÜ" },
            { id: "016203", name: "KARACABEY VERGİ DAİRESİ" },
            { id: "078201", name: "KARABÜK VERGİ DAİRESİ" },
            { id: "035107", name: "KARABURUN MAL MÜDÜRLÜĞÜ" },
            { id: "059267", name: "KAPAKLI VERGİ DAİRESİ" },
            { id: "058106", name: "KANGAL MAL MÜDÜRLÜĞÜ" },
            { id: "041103", name: "KANDIRA MAL MÜDÜRLÜĞÜ" },
            { id: "040261", name: "KAMAN VERGİ DAİRESİ" },
            { id: "053106", name: "KALKANDERE MAL MÜDÜRLÜĞÜ" },
            { id: "038253", name: "KALEÖNÜ VERGİ DAİRESİ" },
            { id: "071202", name: "KALETEPE VERGİ DAİRESİ" },
            { id: "007252", name: "KALEKAPI VERGİ DAİRESİ" },
            { id: "006112", name: "KALECİK MAL MÜDÜRLÜĞÜ" },
            { id: "058201", name: "KALE VERGİ DAİRESİ" },
            { id: "020108", name: "KALE MAL MÜDÜRLÜĞÜ" },
            { id: "044111", name: "KALE MAL MÜDÜRLÜĞÜ" },
            { id: "002261", name: "KAHTA VERGİ DAİRESİ" },
            { id: "006279", name: "KAHRAMANKAZAN VERGİ DAİRESİ" },
            { id: "080260", name: "KADİRLİ VERGİ DAİRESİ" },
            { id: "035261", name: "KADİFEKALE VERGİ DAİRESİ" },
            { id: "066111", name: "KADIŞEHRİ MAL MÜDÜRLÜĞÜ" },
            { id: "042111", name: "KADINHAN MAL MÜDÜRLÜĞÜ" },
            { id: "034272", name: "KADIKÖY VERGİ DAİRESİ" },
            { id: "052118", name: "KABATAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "052117", name: "KABADÜZ MAL MÜDÜRLÜĞÜ" },
            { id: "070103", name: "K.KARABEKİR MAL MÜDÜRLÜĞÜ" },
            { id: "076201", name: "IĞDIR VERGİ DAİRESİ" },
            { id: "071201", name: "IRMAK VERGİ DAİRESİ" },
            { id: "042208", name: "ILGIN VERGİ DAİRESİ" },
            { id: "018104", name: "ILGAZ MAL MÜDÜRLÜĞÜ" },
            { id: "013103", name: "HİZAN MAL MÜDÜRLÜĞÜ" },
            { id: "006268", name: "HİTİT VERGİ DAİRESİ" },
            { id: "043109", name: "HİSARCIK MAL MÜDÜRLÜĞÜ" },
            { id: "034256", name: "HİSAR VERGİ DAİRESİ" },
            { id: "063105", name: "HİLVAN MAL MÜDÜRLÜĞÜ" },
            { id: "042122", name: "HÜYÜK MAL MÜDÜRLÜĞÜ" },
            { id: "062102", name: "HOZAT MAL MÜDÜRLÜĞÜ" },
            { id: "025104", name: "HOROSAN MAL MÜDÜRLÜĞÜ" },
            { id: "008261", name: "HOPA VERGİ DAİRESİ" },
            { id: "020113", name: "HONAZ MAL MÜDÜRLÜĞÜ" },
            { id: "034271", name: "HOCAPAŞA VERGİ DAİRESİ" },
            { id: "003116", name: "HOCALAR MAL MÜDÜRLÜĞÜ" },
            { id: "061201", name: "HIZIRBEY VERGİ DAİRESİ" },
            { id: "025103", name: "HINIS MAL MÜDÜRLÜĞÜ" },
            { id: "054203", name: "HENDEK VERGİ DAİRESİ" },
            { id: "053110", name: "HEMŞİN MAL MÜDÜRLÜĞÜ" },
            { id: "044106", name: "HEKİMHAN MAL MÜDÜRLÜĞÜ" },
            { id: "021108", name: "HAZRO MAL MÜDÜRLÜĞÜ" },
            { id: "023280", name: "HAZAR VERGİ DAİRESİ" },
            { id: "061116", name: "HAYRAT MAL MÜDÜRLÜĞÜ" },
            { id: "059263", name: "HAYRABOLU VERGİ DAİRESİ" },
            { id: "006213", name: "HAYMANA VERGİ DAİRESİ" },
            { id: "055205", name: "HAVZA VERGİ DAİRESİ" },
            { id: "022263", name: "HAVSA VERGİ DAİRESİ" },
            { id: "010110", name: "HAVRAN MAL MÜDÜRLÜĞÜ" },
            { id: "031103", name: "HASSA MAL MÜDÜRLÜĞÜ" },
            { id: "049104", name: "HASKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "072103", name: "HASANKEYF MAL MÜDÜRLÜĞÜ" },
            { id: "080104", name: "HASANBEYLİ MAL MÜDÜRLÜĞÜ" },
            { id: "035263", name: "HASAN TAHSİN VERGİ DAİRESİ" },
            { id: "063110", name: "HARRAN MAL MÜDÜRLÜĞÜ" },
            { id: "023201", name: "HARPUT VERGİ DAİRESİ" },
            { id: "016111", name: "HARMANCIK MAL MÜDÜRLÜĞÜ" },
            { id: "021107", name: "HANİ MAL MÜDÜRLÜĞÜ" },
            { id: "037118", name: "HANÖNÜ MAL MÜDÜRLÜĞÜ" },
            { id: "075104", name: "HANAK MAL MÜDÜRLÜĞÜ" },
            { id: "026111", name: "HAN MAL MÜDÜRLÜĞÜ" },
            { id: "004104", name: "HAMUR MAL MÜDÜRLÜĞÜ" },
            { id: "005106", name: "HAMAMÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "034220", name: "HALİÇ İHTİSAS VERGİ DAİRESİ" },
            { id: "042129", name: "HALKAPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "034278", name: "HALKALI VERGİ DAİRESİ" },
            { id: "063104", name: "HALFETİ MAL MÜDÜRLÜĞÜ" },
            { id: "030260", name: "HAKKARİ VERGİ DAİRESİ" },
            { id: "058104", name: "HAFİK MAL MÜDÜRLÜĞÜ" },
            { id: "042109", name: "HADİM MAL MÜDÜRLÜĞÜ" },
            { id: "038113", name: "HACILAR MAL MÜDÜRLÜĞÜ" },
            { id: "050104", name: "HACIBEKTAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "028260", name: "GİRESUN VERGİ DAİRESİ" },
            { id: "073103", name: "GÜÇLÜKONAK MAL MÜDÜRLÜĞÜ" },
            { id: "068102", name: "GÜZELYURT MAL MÜDÜRLÜĞÜ" },
            { id: "009280", name: "GÜZELHİSAR VERGİ DAİRESİ" },
            { id: "058103", name: "GÜRÜN MAL MÜDÜRLÜĞÜ" },
            { id: "065105", name: "GÜRPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "013106", name: "GÜROYMAK MAL MÜDÜRLÜĞÜ" },
            { id: "052112", name: "GÜRGENTEPE MAL MÜDÜRLÜĞÜ" },
            { id: "026110", name: "GÜNYÜZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "034259", name: "GÜNGÖREN VERGİ DAİRESİ" },
            { id: "034261", name: "GÜNEŞLİ VERGİ DAİRESİ" },
            { id: "053108", name: "GÜNEYSU MAL MÜDÜRLÜĞÜ" },
            { id: "042128", name: "GÜNEYSINIR MAL MÜDÜRLÜĞÜ" },
            { id: "020107", name: "GÜNEY MAL MÜDÜRLÜĞÜ" },
            { id: "007106", name: "GÜNDOĞMUŞ MAL MÜDÜRLÜĞÜ" },
            { id: "081105", name: "GÜMÜŞOVA MAL MÜDÜRLÜĞÜ" },
            { id: "029260", name: "GÜMÜŞHANE VERGİ DAİRESİ" },
            { id: "054251", name: "GÜMRÜKÖNÜ VERGİ DAİRESİ" },
            { id: "050103", name: "GÜLŞEHİR MAL MÜDÜRLÜĞÜ" },
            { id: "052111", name: "GÜLYALI MAL MÜDÜRLÜĞÜ" },
            { id: "033103", name: "GÜLNAR MAL MÜDÜRLÜĞÜ" },
            { id: "068106", name: "GÜLAĞAÇ MAL MÜDÜRLÜĞÜ" },
            { id: "006110", name: "GÜDÜL MAL MÜDÜRLÜĞÜ" },
            { id: "028113", name: "GÜCE MAL MÜDÜRLÜĞÜ" },
            { id: "034270", name: "GÖZTEPE VERGİ DAİRESİ" },
            { id: "014104", name: "GÖYNÜK MAL MÜDÜRLÜĞÜ" },
            { id: "005101", name: "GÖYNÜCEK MAL MÜDÜRLÜĞÜ" },
            { id: "028106", name: "GÖRELE MAL MÜDÜRLÜĞÜ" },
            { id: "045210", name: "GÖRDES VERGİ DAİRESİ" },
            { id: "010264", name: "GÖNEN VERGİ DAİRESİ" },
            { id: "032111", name: "GÖNEN MAL MÜDÜRLÜĞÜ" },
            { id: "010118", name: "GÖMEÇ MAL MÜDÜRLÜĞÜ" },
            { id: "081104", name: "GÖLYAKA MAL MÜDÜRLÜĞÜ" },
            { id: "011102", name: "GÖLPAZARI MAL MÜDÜRLÜĞÜ" },
            { id: "058115", name: "GÖLOVA MAL MÜDÜRLÜĞÜ" },
            { id: "045114", name: "GÖLMARMARA MAL MÜDÜRLÜĞÜ" },
            { id: "052104", name: "GÖLKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "015103", name: "GÖLHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "075103", name: "GÖLE MAL MÜDÜRLÜĞÜ" },
            { id: "041204", name: "GÖLCÜK VERGİ DAİRESİ" },
            { id: "006272", name: "GÖLBAŞI VERGİ DAİRESİ" },
            { id: "002104", name: "GÖLBAŞI MAL MÜDÜRLÜĞÜ" },
            { id: "067113", name: "GÖKÇEBEY MAL MÜDÜRLÜĞÜ" },
            { id: "017109", name: "GÖKÇEADA MAL MÜDÜRLÜĞÜ" },
            { id: "046104", name: "GÖKSUN MAL MÜDÜRLÜĞÜ" },
            { id: "020203", name: "GÖKPINAR VERGİ DAİRESİ" },
            { id: "016259", name: "GÖKDERE VERGİ DAİRESİ" },
            { id: "021251", name: "GÖKALP VERGİ DAİRESİ" },
            { id: "063281", name: "GÖBEKLİTEPE VERGİ DAİRESİ" },
            { id: "054202", name: "GEYVE VERGİ DAİRESİ" },
            { id: "038254", name: "GEVHER NESİBE VERGİ DAİRESİ" },
            { id: "065104", name: "GEVAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "057105", name: "GERZE MAL MÜDÜRLÜĞÜ" },
            { id: "009263", name: "GERMENCİK VERGİ DAİRESİ" },
            { id: "002103", name: "GERGER MAL MÜDÜRLÜĞÜ" },
            { id: "014262", name: "GEREDE VERGİ DAİRESİ" },
            { id: "072102", name: "GERCÜŞ MAL MÜDÜRLÜĞÜ" },
            { id: "012101", name: "GENÇ MAL MÜDÜRLÜĞÜ" },
            { id: "016201", name: "GEMLİK VERGİ DAİRESİ" },
            { id: "058102", name: "GEMEREK MAL MÜDÜRLÜĞÜ" },
            { id: "017263", name: "GELİBOLU VERGİ DAİRESİ" },
            { id: "032103", name: "GELENDOST MAL MÜDÜRLÜĞÜ" },
            { id: "043260", name: "GEDİZ VERGİ DAİRESİ" },
            { id: "041254", name: "GEBZE İHTİSAS VERGİ DAİRESİ" },
            { id: "007105", name: "GAZİPAŞA MAL MÜDÜRLÜĞÜ" },
            { id: "034269", name: "GAZİOSMANPAŞA VERGİ DAİRESİ" },
            { id: "055252", name: "GAZİLER VERGİ DAİRESİ" },
            { id: "027254", name: "GAZİKENT VERGİ DAİRESİ" },
            { id: "035267", name: "GAZİEMİR VERGİ DAİRESİ" },
            { id: "027250", name: "G.ANTEP İHTİSAS VERGİ DAİRESİ" },
            { id: "005262", name: "G. HACIKÖY VERGİ DAİRESİ" },
            { id: "007104", name: "FİNİKE MAL MÜDÜRLÜĞÜ" },
            { id: "035106", name: "FOÇA MAL MÜDÜRLÜĞÜ" },
            { id: "044251", name: "FIRAT VERGİ DAİRESİ" },
            { id: "053104", name: "FINDIKLI MAL MÜDÜRLÜĞÜ" },
            { id: "024260", name: "FEVZİPAŞA VERGİ DAİRESİ" },
            { id: "048262", name: "FETHİYE VERGİ DAİRESİ" },
            { id: "038103", name: "FELAHİYE MAL MÜDÜRLÜĞÜ" },
            { id: "001103", name: "FEKE MAL MÜDÜRLÜĞÜ" },
            { id: "034236", name: "FATİH VERGİ DAİRESİ" },
            { id: "052261", name: "FATSA VERGİ DAİRESİ" },
            { id: "064262", name: "EŞME VERGİ DAİRESİ" },
            { id: "032261", name: "EĞİRDİR VERGİ DAİRESİ" },
            { id: "021112", name: "EĞİL MAL MÜDÜRLÜĞÜ" },
            { id: "017107", name: "EZİNE MAL MÜDÜRLÜĞÜ" },
            { id: "028105", name: "EYNESİL MAL MÜDÜRLÜĞÜ" },
            { id: "006127", name: "EVREN MAL MÜDÜRLÜĞÜ" },
            { id: "003115", name: "EVCİLER MAL MÜDÜRLÜĞÜ" },
            { id: "006275", name: "ETİMESGUT VERGİ DAİRESİ" },
            { id: "028104", name: "ESPİYE MAL MÜDÜRLÜĞÜ" },
            { id: "026250", name: "ESKİŞEHİR VERGİ DAİRESİ" },
            { id: "078102", name: "ESKİPAZAR MAL MÜDÜRLÜĞÜ" },
            { id: "068105", name: "ESKİL MAL MÜDÜRLÜĞÜ" },
            { id: "034298", name: "ESENYURT VERGİ DAİRESİ" },
            { id: "034235", name: "ESENLER VERGİ DAİRESİ" },
            { id: "031109", name: "ERZİN MAL MÜDÜRLÜĞÜ" },
            { id: "056104", name: "ERUH MAL MÜDÜRLÜĞÜ" },
            { id: "016258", name: "ERTUĞRULGAZİ VERGİ DAİRESİ" },
            { id: "070102", name: "ERMENEK MAL MÜDÜRLÜĞÜ" },
            { id: "021283", name: "ERGANİ VERGİ DAİRESİ" },
            { id: "057104", name: "ERFELEK MAL MÜDÜRLÜĞÜ" },
            { id: "042202", name: "EREĞLİ VERGİ DAİRESİ" },
            { id: "034255", name: "ERENKÖY VERGİ DAİRESİ" },
            { id: "033201", name: "ERDEMLİ VERGİ DAİRESİ" },
            { id: "010266", name: "ERDEK VERGİ DAİRESİ" },
            { id: "065201", name: "ERCİŞ VERGİ DAİRESİ" },
            { id: "038252", name: "ERCİYES VERGİ DAİRESİ" },
            { id: "060261", name: "ERBAA VERGİ DAİRESİ" },
            { id: "022101", name: "ENEZ MAL MÜDÜRLÜĞÜ" },
            { id: "042127", name: "EMİRGAZİ MAL MÜDÜRLÜĞÜ" },
            { id: "003262", name: "EMİRDAĞ VERGİ DAİRESİ" },
            { id: "043263", name: "EMET VERGİ DAİRESİ" },
            { id: "007204", name: "ELMALI VERGİ DAİRESİ" },
            { id: "006215", name: "ELMADAĞ VERGİ DAİRESİ" },
            { id: "004103", name: "ELEŞKİRT MAL MÜDÜRLÜĞÜ" },
            { id: "018102", name: "ELDİVAN MAL MÜDÜRLÜĞÜ" },
            { id: "046260", name: "ELBİSTAN VERGİ DAİRESİ" },
            { id: "046108", name: "EKİNÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "035268", name: "EGE VERGİ DAİRESİ" },
            { id: "078101", name: "EFLANİ MAL MÜDÜRLÜĞÜ" },
            { id: "009201", name: "EFELER VERGİ DAİRESİ" },
            { id: "010263", name: "EDREMİT VERGİ DAİRESİ" },
            { id: "065110", name: "EDREMİT MAL MÜDÜRLÜĞÜ" },
            { id: "017106", name: "ECEABAT MAL MÜDÜRLÜĞÜ" },
            { id: "004101", name: "DİYADİN MAL MÜDÜRLÜĞÜ" },
            { id: "058101", name: "DİVRİĞİ MAL MÜDÜRLÜĞÜ" },
            { id: "003260", name: "DİNAR VERGİ DAİRESİ" },
            { id: "006264", name: "DİKİMEVİ VERGİ DAİRESİ" },
            { id: "035105", name: "DİKİLİ MAL MÜDÜRLÜĞÜ" },
            { id: "057107", name: "DİKMEN MAL MÜDÜRLÜĞÜ" },
            { id: "036105", name: "DİGOR MAL MÜDÜRLÜĞÜ" },
            { id: "009281", name: "DİDİM VERGİ DAİRESİ" },
            { id: "021105", name: "DİCLE MAL MÜDÜRLÜĞÜ" },
            { id: "080102", name: "DÜZİÇİ MAL MÜDÜRLÜĞÜ" },
            { id: "061115", name: "DÜZKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "081260", name: "DÜZCE VERGİ DAİRESİ" },
            { id: "007254", name: "DÜDEN VERGİ DAİRESİ" },
            { id: "031260", name: "DÖRTYOL VERGİ DAİRESİ" },
            { id: "014113", name: "DÖRTDİVAN MAL MÜDÜRLÜĞÜ" },
            { id: "010269", name: "DURSUNBEY VERGİ DAİRESİ" },
            { id: "057103", name: "DURAĞAN MAL MÜDÜRLÜĞÜ" },
            { id: "043108", name: "DUMLUPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "004261", name: "DOĞUBEYAZIT VERGİ DAİRESİ" },
            { id: "044105", name: "DOĞANŞEHİR MAL MÜDÜRLÜĞÜ" },
            { id: "058114", name: "DOĞANŞAR MAL MÜDÜRLÜĞÜ" },
            { id: "037117", name: "DOĞANYURT MAL MÜDÜRLÜĞÜ" },
            { id: "044110", name: "DOĞANYOL MAL MÜDÜRLÜĞÜ" },
            { id: "028115", name: "DOĞANKENT MAL MÜDÜRLÜĞÜ" },
            { id: "042106", name: "DOĞANHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "006265", name: "DOĞANBEY VERGİ DAİRESİ" },
            { id: "043102", name: "DOMANİÇ MAL MÜDÜRLÜĞÜ" },
            { id: "019111", name: "DODURGA MAL MÜDÜRLÜĞÜ" },
            { id: "006274", name: "DIŞKAPI VERGİ DAİRESİ" },
            { id: "037107", name: "DEVREKANİ MAL MÜDÜRLÜĞÜ" },
            { id: "067264", name: "DEVREK VERGİ DAİRESİ" },
            { id: "038201", name: "DEVELİ VERGİ DAİRESİ" },
            { id: "050102", name: "DERİNKUYU MAL MÜDÜRLÜĞÜ" },
            { id: "041207", name: "DERİNCE VERGİ DAİRESİ" },
            { id: "047102", name: "DERİK MAL MÜDÜRLÜĞÜ" },
            { id: "061114", name: "DERNEKPAZARI MAL MÜDÜRLÜĞÜ" },
            { id: "053109", name: "DEREPAZARI MAL MÜDÜRLÜĞÜ" },
            { id: "028103", name: "DERELİ MAL MÜDÜRLÜĞÜ" },
            { id: "030104", name: "DERECİK MAL MÜDÜRLÜĞÜ" },
            { id: "042121", name: "DEREBUCAK MAL MÜDÜRLÜĞÜ" },
            { id: "042126", name: "DERBENT MAL MÜDÜRLÜĞÜ" },
            { id: "020250", name: "DENİZLİ İHTİSAS VERGİ DAİRESİ" },
            { id: "069102", name: "DEMİRÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "039102", name: "DEMİRKÖY MAL MÜDÜRLÜĞÜ" },
            { id: "045203", name: "DEMİRCİ VERGİ DAİRESİ" },
            { id: "007112", name: "DEMRE MAL MÜDÜRLÜĞÜ" },
            { id: "071101", name: "DELİCE MAL MÜDÜRLÜĞÜ" },
            { id: "003103", name: "DAZKIRI MAL MÜDÜRLÜĞÜ" },
            { id: "034234", name: "DAVUTPAŞA VERGİ DAİRESİ" },
            { id: "032201", name: "DAVRAZ VERGİ DAİRESİ" },
            { id: "048102", name: "DATÇA MAL MÜDÜRLÜĞÜ" },
            { id: "047112", name: "DARGEÇİT MAL MÜDÜRLÜĞÜ" },
            { id: "044104", name: "DARENDE MAL MÜDÜRLÜĞÜ" },
            { id: "075102", name: "DAMAL MAL MÜDÜRLÜĞÜ" },
            { id: "048109", name: "DALAMAN MAL MÜDÜRLÜĞÜ" },
            { id: "037106", name: "DADAY MAL MÜDÜRLÜĞÜ" },
            { id: "073261", name: "CİZRE VERGİ DAİRESİ" },
            { id: "042205", name: "CİHANBEYLİ VERGİ DAİRESİ" },
            { id: "037104", name: "CİDE MAL MÜDÜRLÜĞÜ" },
            { id: "006277", name: "CUMHURİYET VERGİ DAİRESİ" },
            { id: "081102", name: "CUMAYERİ MAL MÜDÜRLÜĞÜ" },
            { id: "063109", name: "CEYLANPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "001201", name: "CEYHAN VERGİ DAİRESİ" },
            { id: "021282", name: "C.SITKI TARANCI VERGİ DAİRESİ" },
            { id: "013260", name: "BİTLİS VERGİ DAİRESİ" },
            { id: "021284", name: "BİSMİL VERGİ DAİRESİ" },
            { id: "063262", name: "BİRECİK VERGİ DAİRESİ" },
            { id: "012260", name: "BİNGÖL VERGİ DAİRESİ" },
            { id: "011260", name: "BİLECİK VERGİ DAİRESİ" },
            { id: "010267", name: "BİGADİÇ VERGİ DAİRESİ" },
            { id: "017261", name: "BİGA VERGİ DAİRESİ" },
            { id: "034204", name: "BÜYÜKÇEKMECE VERGİ DAİRESİ" },
            { id: "016112", name: "BÜYÜKORHAN MAL MÜDÜRLÜĞÜ" },
            { id: "034230", name: "BÜYÜK MÜK. VD. B VERGİ DAİRESİ" },
            { id: "038203", name: "BÜNYAN VERGİ DAİRESİ" },
            { id: "016250", name: "BURSA İHTİSAS VERGİ DAİRESİ" },
            { id: "016260", name: "BURSA VER.HARÇ. VERGİ DAİRESİ" },
            { id: "010262", name: "BURHANİYE VERGİ DAİRESİ" },
            { id: "015260", name: "BURDUR VERGİ DAİRESİ" },
            { id: "020263", name: "BULDAN VERGİ DAİRESİ" },
            { id: "049101", name: "BULANIK MAL MÜDÜRLÜĞÜ" },
            { id: "028261", name: "BULANCAK VERGİ DAİRESİ" },
            { id: "009112", name: "BUHARKENT MAL MÜDÜRLÜĞÜ" },
            { id: "015261", name: "BUCAK VERGİ DAİRESİ" },
            { id: "034231", name: "BOĞAZİÇİ KUR. VERGİ DAİRESİ" },
            { id: "066261", name: "BOĞAZLIYAN VERGİ DAİRESİ" },
            { id: "019109", name: "BOĞAZKALE MAL MÜDÜRLÜĞÜ" },
            { id: "011261", name: "BOZÜYÜK VERGİ DAİRESİ" },
            { id: "033108", name: "BOZYAZI MAL MÜDÜRLÜĞÜ" },
            { id: "052201", name: "BOZTEPE VERGİ DAİRESİ" },
            { id: "040106", name: "BOZTEPE MAL MÜDÜRLÜĞÜ" },
            { id: "063103", name: "BOZOVA MAL MÜDÜRLÜĞÜ" },
            { id: "020118", name: "BOZKURT MAL MÜDÜRLÜĞÜ" },
            { id: "037103", name: "BOZKURT MAL MÜDÜRLÜĞÜ" },
            { id: "042103", name: "BOZKIR MAL MÜDÜRLÜĞÜ" },
            { id: "009101", name: "BOZDOĞAN MAL MÜDÜRLÜĞÜ" },
            { id: "017104", name: "BOZCAADA MAL MÜDÜRLÜĞÜ" },
            { id: "057261", name: "BOYABAT VERGİ DAİRESİ" },
            { id: "008103", name: "BORÇKA MAL MÜDÜRLÜĞÜ" },
            { id: "035264", name: "BORNOVA VERGİ DAİRESİ" },
            { id: "051262", name: "BOR VERGİ DAİRESİ" },
            { id: "003261", name: "BOLVADİN VERGİ DAİRESİ" },
            { id: "014260", name: "BOLU VERGİ DAİRESİ" },
            { id: "048261", name: "BODRUM VERGİ DAİRESİ" },
            { id: "072101", name: "BEŞİRİ MAL MÜDÜRLÜĞÜ" },
            { id: "034251", name: "BEŞİKTAŞ VERGİ DAİRESİ" },
            { id: "061111", name: "BEŞİKDÜZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "042204", name: "BEYŞEHİR VERGİ DAİRESİ" },
            { id: "073101", name: "BEYTÜŞŞEBAB MAL MÜDÜRLÜĞÜ" },
            { id: "006209", name: "BEYPAZARI VERGİ DAİRESİ" },
            { id: "034266", name: "BEYOĞLU VERGİ DAİRESİ" },
            { id: "026108", name: "BEYLİKOVA MAL MÜDÜRLÜĞÜ" },
            { id: "034297", name: "BEYLİKDÜZÜ VERGİ DAİRESİ" },
            { id: "034281", name: "BEYKOZ VERGİ DAİRESİ" },
            { id: "044252", name: "BEYDAĞI VERGİ DAİRESİ" },
            { id: "035120", name: "BEYDAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "020117", name: "BEYAĞAÇ MAL MÜDÜRLÜĞÜ" },
            { id: "034265", name: "BEYAZIT VERGİ DAİRESİ" },
            { id: "002101", name: "BESNİ MAL MÜDÜRLÜĞÜ" },
            { id: "035202", name: "BERGAMA VERGİ DAİRESİ" },
            { id: "035254", name: "BELKAHVE VERGİ DAİRESİ" },
            { id: "031110", name: "BELEN MAL MÜDÜRLÜĞÜ" },
            { id: "020112", name: "BEKİLLİ MAL MÜDÜRLÜĞÜ" },
            { id: "060110", name: "BAŞÇİFTLİK MAL MÜDÜRLÜĞÜ" },
            { id: "070104", name: "BAŞYAYLA MAL MÜDÜRLÜĞÜ" },
            { id: "003111", name: "BAŞMAKÇI MAL MÜDÜRLÜĞÜ" },
            { id: "006276", name: "BAŞKENT VERGİ DAİRESİ" },
            { id: "065101", name: "BAŞKALE MAL MÜDÜRLÜĞÜ" },
            { id: "017102", name: "BAYRAMİÇ MAL MÜDÜRLÜĞÜ" },
            { id: "018112", name: "BAYRAMÖREN MAL MÜDÜRLÜĞÜ" },
            { id: "034264", name: "BAYRAMPAŞA VERGİ DAİRESİ" },
            { id: "056102", name: "BAYKAN MAL MÜDÜRLÜĞÜ" },
            { id: "035201", name: "BAYINDIR VERGİ DAİRESİ" },
            { id: "069201", name: "BAYBURT VERGİ DAİRESİ" },
            { id: "003112", name: "BAYAT MAL MÜDÜRLÜĞÜ" },
            { id: "019102", name: "BAYAT MAL MÜDÜRLÜĞÜ" },
            { id: "044109", name: "BATTALGAZİ MAL MÜDÜRLÜĞÜ" },
            { id: "072260", name: "BATMAN VERGİ DAİRESİ" },
            { id: "023102", name: "BASKİL MAL MÜDÜRLÜĞÜ" },
            { id: "074260", name: "BARTIN VERGİ DAİRESİ" },
            { id: "010261", name: "BANDIRMA VERGİ DAİRESİ" },
            { id: "064261", name: "BANAZ VERGİ DAİRESİ" },
            { id: "035266", name: "BALÇOVA VERGİ DAİRESİ" },
            { id: "010102", name: "BALYA MAL MÜDÜRLÜĞÜ" },
            { id: "071105", name: "BALIŞEYH MAL MÜDÜRLÜĞÜ" },
            { id: "006103", name: "BALA MAL MÜDÜRLÜĞÜ" },
            { id: "020116", name: "BAKLAN MAL MÜDÜRLÜĞÜ" },
            { id: "034284", name: "BAKIRKÖY VERGİ DAİRESİ" },
            { id: "065108", name: "BAHÇESARAY MAL MÜDÜRLÜĞÜ" },
            { id: "080101", name: "BAHÇE MAL MÜDÜRLÜĞÜ" },
            { id: "055202", name: "BAFRA VERGİ DAİRESİ" },
            { id: "039262", name: "BABAESKİ VERGİ DAİRESİ" },
            { id: "020111", name: "BABADAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "025101", name: "AŞKALE MAL MÜDÜRLÜĞÜ" },
            { id: "004260", name: "AĞRI VERGİ DAİRESİ" },
            { id: "037116", name: "AĞLI MAL MÜDÜRLÜĞÜ" },
            { id: "015101", name: "AĞLASUN MAL MÜDÜRLÜĞÜ" },
            { id: "023101", name: "AĞIN MAL MÜDÜRLÜĞÜ" },
            { id: "068101", name: "AĞAÇÖREN MAL MÜDÜRLÜĞÜ" },
            { id: "025251", name: "AZİZİYE VERGİ DAİRESİ" },
            { id: "025117", name: "AZİZİYE (ILICA) MAL MÜDÜRLÜĞÜ" },
            { id: "037102", name: "AZDAVAY MAL MÜDÜRLÜĞÜ" },
            { id: "010260", name: "AYVALIK VERGİ DAİRESİ" },
            { id: "017101", name: "AYVACIK MAL MÜDÜRLÜĞÜ" },
            { id: "055113", name: "AYVACIK MAL MÜDÜRLÜĞÜ" },
            { id: "070101", name: "AYRANCI MAL MÜDÜRLÜĞÜ" },
            { id: "069101", name: "AYDINTEPE MAL MÜDÜRLÜĞÜ" },
            { id: "033107", name: "AYDINCIK MAL MÜDÜRLÜĞÜ" },
            { id: "066109", name: "AYDINCIK MAL MÜDÜRLÜĞÜ" },
            { id: "052102", name: "AYBASTI MAL MÜDÜRLÜĞÜ" },
            { id: "006102", name: "AYAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "057101", name: "AYANCIK MAL MÜDÜRLÜĞÜ" },
            { id: "034294", name: "AVCILAR VERGİ DAİRESİ" },
            { id: "050101", name: "AVANOS MAL MÜDÜRLÜĞÜ" },
            { id: "018110", name: "ATKARACALAR MAL MÜDÜRLÜĞÜ" },
            { id: "034291", name: "ATIŞALANI VERGİ DAİRESİ" },
            { id: "032101", name: "ATABEY MAL MÜDÜRLÜĞÜ" },
            { id: "046201", name: "ASLANBEY VERGİ DAİRESİ" },
            { id: "043107", name: "ASLANAPA MAL MÜDÜRLÜĞÜ" },
            { id: "031290", name: "ASIM GÜNDÜZ VERGİ DAİRESİ" },
            { id: "055109", name: "ASARCIK MAL MÜDÜRLÜĞÜ" },
            { id: "008260", name: "ARTVİN VERGİ DAİRESİ" },
            { id: "060102", name: "ARTOVA MAL MÜDÜRLÜĞÜ" },
            { id: "061103", name: "ARSİN MAL MÜDÜRLÜĞÜ" },
            { id: "036103", name: "ARPAÇAY MAL MÜDÜRLÜĞÜ" },
            { id: "077102", name: "ARMUTLU MAL MÜDÜRLÜĞÜ" },
            { id: "023108", name: "ARICAK MAL MÜDÜRLÜĞÜ" },
            { id: "008262", name: "ARHAVİ VERGİ DAİRESİ" },
            { id: "044103", name: "ARGUVAN MAL MÜDÜRLÜĞÜ" },
            { id: "053263", name: "ARDEŞEN VERGİ DAİRESİ" },
            { id: "008101", name: "ARDANUÇ MAL MÜDÜRLÜĞÜ" },
            { id: "075201", name: "ARDAHAN VERGİ DAİRESİ" },
            { id: "022201", name: "ARDA VERGİ DAİRESİ" },
            { id: "037101", name: "ARAÇ MAL MÜDÜRLÜĞÜ" },
            { id: "044102", name: "ARAPKİR MAL MÜDÜRLÜĞÜ" },
            { id: "076101", name: "ARALIK MAL MÜDÜRLÜĞÜ" },
            { id: "061102", name: "ARAKLI MAL MÜDÜRLÜĞÜ" },
            { id: "027101", name: "ARABAN MAL MÜDÜRLÜĞÜ" },
            { id: "007256", name: "ANTALYA İHTİSAS VERGİ DAİRESİ" },
            { id: "007255", name: "ANTALYA KURUMLAR VERGİ DAİRESİ" },
            { id: "031203", name: "ANTAKYA VERGİ DAİRESİ" },
            { id: "006281", name: "ANKARA İHTİSAS VERGİ DAİRESİ" },
            { id: "046102", name: "ANDIRIN MAL MÜDÜRLÜĞÜ" },
            { id: "033203", name: "ANAMUR VERGİ DAİRESİ" },
            { id: "006280", name: "ANADOLU İHTİSAS VERGİ DAİRESİ" },
            { id: "034244", name: "ANADOLU KURUMLAR VERGİ DAİRESİ" },
            { id: "005260", name: "AMASYA VERGİ DAİRESİ" },
            { id: "074101", name: "AMASRA MAL MÜDÜRLÜĞÜ" },
            { id: "035213", name: "ALİAĞA VERGİ DAİRESİ" },
            { id: "054252", name: "ALİ FUAT CEBESOY VERGİ DAİRESİ" },
            { id: "028101", name: "ALUCRA MAL MÜDÜRLÜĞÜ" },
            { id: "051106", name: "ALTUNHİSAR MAL MÜDÜRLÜĞÜ" },
            { id: "031101", name: "ALTINÖZÜ MAL MÜDÜRLÜĞÜ" },
            { id: "015108", name: "ALTINYAYLA MAL MÜDÜRLÜĞÜ" },
            { id: "058113", name: "ALTINYAYLA MAL MÜDÜRLÜĞÜ" },
            { id: "043101", name: "ALTINTAŞ MAL MÜDÜRLÜĞÜ" },
            { id: "077101", name: "ALTINOVA MAL MÜDÜRLÜĞÜ" },
            { id: "042119", name: "ALTINEKİN MAL MÜDÜRLÜĞÜ" },
            { id: "026107", name: "ALPU MAL MÜDÜRLÜĞÜ" },
            { id: "060101", name: "ALMUS MAL MÜDÜRLÜĞÜ" },
            { id: "034223", name: "ALEMDAĞ VERGİ DAİRESİ" },
            { id: "041253", name: "ALEMDAR VERGİ DAİRESİ" },
            { id: "045202", name: "ALAŞEHİR VERGİ DAİRESİ" },
            { id: "055101", name: "ALAÇAM MAL MÜDÜRLÜĞÜ" },
            { id: "067110", name: "ALAPLI MAL MÜDÜRLÜĞÜ" },
            { id: "007201", name: "ALANYA VERGİ DAİRESİ" },
            { id: "001114", name: "ALADAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "023110", name: "ALACAKAYA MAL MÜDÜRLÜĞÜ" },
            { id: "019101", name: "ALACA MAL MÜDÜRLÜĞÜ" },
            { id: "042254", name: "ALAADDİN VERGİ DAİRESİ" },
            { id: "042201", name: "AKŞEHİR VERGİ DAİRESİ" },
            { id: "042118", name: "AKÖREN MAL MÜDÜRLÜĞÜ" },
            { id: "081261", name: "AKÇAKOCA VERGİ DAİRESİ" },
            { id: "040105", name: "AKÇAKENT MAL MÜDÜRLÜĞÜ" },
            { id: "063101", name: "AKÇAKALE MAL MÜDÜRLÜĞÜ" },
            { id: "044101", name: "AKÇADAĞ MAL MÜDÜRLÜĞÜ" },
            { id: "061260", name: "AKÇAABAT VERGİ DAİRESİ" },
            { id: "006125", name: "AKYURT MAL MÜDÜRLÜĞÜ" },
            { id: "054201", name: "AKYAZI VERGİ DAİRESİ" },
            { id: "036115", name: "AKYAKA MAL MÜDÜRLÜĞÜ" },
            { id: "046280", name: "AKSU VERGİ DAİRESİ" },
            { id: "032110", name: "AKSU MAL MÜDÜRLÜĞÜ" },
            { id: "007101", name: "AKSEKİ MAL MÜDÜRLÜĞÜ" },
            { id: "068201", name: "AKSARAY VERGİ DAİRESİ" },
            { id: "040104", name: "AKPINAR MAL MÜDÜRLÜĞÜ" },
            { id: "052101", name: "AKKUŞ MAL MÜDÜRLÜĞÜ" },
            { id: "038111", name: "AKKIŞLA MAL MÜDÜRLÜĞÜ" },
            { id: "058112", name: "AKINCILAR MAL MÜDÜRLÜĞÜ" },
            { id: "045201", name: "AKHİSAR VERGİ DAİRESİ" },
            { id: "031281", name: "AKDENİZ VERGİ DAİRESİ" },
            { id: "066101", name: "AKDAĞMADENİ MAL MÜDÜRLÜĞÜ" },
            { id: "045113", name: "AHMETLİ MAL MÜDÜRLÜĞÜ" },
            { id: "013102", name: "AHLAT MAL MÜDÜRLÜĞÜ" },
            { id: "042124", name: "AHIRLI MAL MÜDÜRLÜĞÜ" },
            { id: "046261", name: "AFŞİN VERGİ DAİRESİ" },
            { id: "013101", name: "ADİLCEVAZ MAL MÜDÜRLÜĞÜ" },
            { id: "002260", name: "ADIYAMAN VERGİ DAİRESİ" },
            { id: "001250", name: "ADANA İHTİSAS VERGİ DAİRESİ" },
            { id: "034295", name: "ADALAR VERGİ DAİRESİ" },
            { id: "012105", name: "ADAKLI MAL MÜDÜRLÜĞÜ" },
            { id: "041290", name: "ACISU VERGİ DAİRESİ" },
            { id: "020261", name: "ACIPAYAM VERGİ DAİRESİ" },
            { id: "050107", name: "ACIGÖL MAL MÜDÜRLÜĞÜ" },
            { id: "037112", name: "ABANA MAL MÜDÜRLÜĞÜ" },
            { id: "035251", name: "9 EYLÜL VERGİ DAİRESİ" },
            { id: "001251", name: "5OCAK VERGİ DAİRESİ" },
            { id: "043201", name: "30 AĞUSTOS VERGİ DAİRESİ" },
            { id: "031201", name: "23TEMMUZ VERGİ DAİRESİ" },
            { id: "055251", name: "19 MAYIS VERGİ DAİRESİ" },
            { id: "055110", name: "19 MAYIS MAL MÜDÜRLÜĞÜ" },
          ],
        },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "TUR",
  },
  {
    name: "Uganda",
    id: "ug",
    supportedByProducts: ["returns"],
    form: {
      fields: [
        {
          required: true,
          name: "tin",
        },
      ],
    },
    alpha3code: "UGA",
  },
  {
    name: "Ukraine",
    id: "ua",
    form: {
      fields: [
        {
          required: true,

          name: "tin",
        },
      ],
    },
    supportedByProducts: ["returns"],
    alpha3code: "UKR",
  },
  {
    name: "United Arab Emirates",
    id: "ae",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "ARE",
  },
  {
    name: "United Kingdom",
    id: "gb",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    supportedByProducts: ["returns"],
    alpha3code: "GBR",
  },
  {
    name: "Uruguay",
    id: "uy",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "URY",
    supportedByProducts: ["returns"],
  },
  {
    name: "Venezuela",
    id: "ve",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "VEN",
    supportedByProducts: ["returns"],
  },
  {
    name: "Vietnam",
    id: "vn",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "VNM",
    supportedByProducts: ["returns"],
  },
  {
    name: "Zambia",
    id: "zm",
    form: {
      fields: [{ required: true, name: "tin" }],
    },
    alpha3code: "ZMB",
    supportedByProducts: ["returns"],
  },
  {
    name: "Zimbabwe",
    id: "zw",
    alpha3code: "ZWE",
    supportedByProducts: ["returns"],
  },
];

export const stubLookupCountries: Record<string, LookupCountryData> = {
  ee: {
    country_iso: "ee",
    country_name: "Estonia",
    local_implementation: true,
    vies_implementation: true,
    has_checksum: true,
    additional_parameters: [],
    additional_response_data: [
      { name: "company_name", type: "string", optional: false, description: "Name of the entity" },
      {
        name: "taxpayer",
        type: "object",
        optional: false,
        description: "",
        object_fields: [{ name: "address", type: "string", optional: false, description: "" }],
      },
    ],
    has_individual_tin_format_checksum_support: false,
    has_individual_tin_online_validation_support: false,
    has_local_source_without_tax_registered_support: false,
  },
  tr: {
    country_iso: "tr",
    country_name: "Turkey",
    local_implementation: true,
    vies_implementation: false,
    has_checksum: false,
    additional_parameters: [
      {
        name: "turkey_tax_office",
        type: "string",
        optional: false,
        description: "Tax office is required for Turkey",
      },
    ],
    additional_response_data: [
      { name: "name", type: "string", optional: false, description: "Name of the entity" },
    ],
    has_individual_tin_format_checksum_support: false,
    has_individual_tin_online_validation_support: false,
    has_local_source_without_tax_registered_support: false,
  },
  hr: {
    country_iso: "hr",
    country_name: "Croatia",
    local_implementation: true,
    vies_implementation: true,
    has_checksum: true,
    additional_parameters: [
      {
        name: "date",
        type: "date",
        optional: true,
        description: "Optional: Enter date to check if TIN is valid for the moment",
      },
    ],
    additional_response_data: [
      { name: "company_name", type: "string", optional: false, description: "Name of the entity" },
      {
        name: "address",
        type: "string",
        optional: false,
        description: "",
        limited_to_validation_source: "vies",
      },
    ],
    has_individual_tin_format_checksum_support: false,
    has_individual_tin_online_validation_support: false,
    has_local_source_without_tax_registered_support: false,
  },
  ca: {
    country_iso: "ca",
    country_name: "Canada",
    local_implementation: true,
    vies_implementation: false,
    has_checksum: false,
    additional_parameters: [
      {
        name: "name",
        type: "string",
        optional: true,
        description:
          "Optional: Taxpayer name can be added to perform a direct search, otherwise Fonoa will identify the name based on the TIN itself",
      },
      {
        name: "date",
        type: "date",
        optional: true,
        description: "Optional: Enter date to check if TIN is valid for the moment",
      },
    ],
    additional_response_data: [
      { name: "name", type: "string", optional: false, description: "Name of the entity" },
    ],
    has_individual_tin_format_checksum_support: false,
    has_individual_tin_online_validation_support: false,
    has_local_source_without_tax_registered_support: false,
  },
  es: {
    country_iso: "es",
    country_name: "Spain",
    local_implementation: true,
    vies_implementation: true,
    has_checksum: true,
    additional_parameters: [
      {
        name: "name",
        type: "string",
        optional: true,
        description:
          "Taxpayer name must be provided if you are validating TINs belonging to natural persons",
      },
    ],
    additional_response_data: [
      {
        name: "name",
        type: "string",
        optional: false,
        description: "Name of the entity",
        limited_to_validation_source: "local",
      },
      {
        name: "is_business",
        type: "string",
        optional: false,
        description: "Specifies whether the taxpayer is an individual, or a legal entity",
        possible_values: ["yes", "no"],
      },
    ],
    has_individual_tin_format_checksum_support: true,
    has_individual_tin_online_validation_support: true,
    has_local_source_without_tax_registered_support: false,
  },
  lt: {
    country_iso: "lt",
    country_name: "Lithuania",
    local_implementation: true,
    vies_implementation: true,
    has_checksum: true,
    additional_parameters: [
      {
        name: "name",
        type: "string",
        optional: true,
        description:
          "Taxpayer name must be provided if you are validating TINs belonging to natural persons",
      },
    ],
    additional_response_data: [
      {
        name: "is_business",
        type: "string",
        optional: false,
        description: "Specifies whether the taxpayer is an individual, or a legal entity",
        possible_values: ["yes", "no"],
      },
      { name: "name", type: "string", optional: false, description: "Name of the entity" },
      { name: "address", type: "string", optional: false, description: "" },
    ],
    has_individual_tin_format_checksum_support: true,
    has_individual_tin_online_validation_support: true,
    has_local_source_without_tax_registered_support: false,
  },
  co: {
    country_iso: "co",
    country_name: "Colombia",
    local_implementation: true,
    vies_implementation: false,
    has_checksum: false,
    additional_parameters: [],
    additional_response_data: [
      {
        name: "is_business",
        type: "string",
        optional: false,
        description: "Specifies whether the taxpayer is an individual, or a legal entity",
        possible_values: ["yes", "no"],
      },
      {
        name: "business_status",
        type: "string",
        optional: false,
        description:
          "Specifies whether the company identified by its TIN is currently active in the relevant government register, regardless of whether it is registered for VAT/GST.",
        possible_values: ["active", "inactive"],
      },
      { name: "name", type: "string", optional: true, description: "Name of the entity" },
    ],
    has_individual_tin_format_checksum_support: false,
    has_individual_tin_online_validation_support: false,
    has_local_source_without_tax_registered_support: false,
  },
  in: {
    country_iso: "in",
    country_name: "India",
    local_implementation: true,
    vies_implementation: false,
    has_checksum: false,
    additional_parameters: [
      {
        name: "india_state_name",
        type: "string",
        optional: true,
        description:
          "Used for validating whether the GSTIN corresponds to the relevant state where the taxpayer is based (optional)",
      },
    ],
    additional_response_data: [
      {
        name: "is_business",
        type: "string",
        optional: false,
        description: "Specifies whether the taxpayer is an individual, or a legal entity",
        possible_values: ["yes", "no"],
      },
      { name: "name", type: "string", optional: false, description: "Name of the entity" },
      {
        name: "tax_registration_type",
        type: "string",
        optional: true,
        description: "",
        possible_values: [
          "Regular",
          "Composition",
          "Special Economic Zone",
          "Casual Taxable Person",
          "Unknown",
          "Non Resident Online Services Provider",
        ],
      },
      { name: "legal_entity_type", type: "string", optional: true, description: "" },
      { name: "registration_date", type: "date", optional: true, description: "" },
      { name: "de_registration_date", type: "date", optional: true, description: "" },
      { name: "address", type: "string", optional: true, description: "" },
    ],
    has_individual_tin_format_checksum_support: true,
    has_individual_tin_online_validation_support: true,
    has_local_source_without_tax_registered_support: false,
  },
};

export const stubLookupSupportedCountries: LookupSupportedCountries = {
  availableCountries: stubLookupCountries,
  allCountries: {
    ...stubLookupCountries,
    cn: {
      country_iso: "cn",
      country_name: "China",
      local_implementation: true,
      vies_implementation: false,
      has_checksum: false,
      additional_parameters: [],
      additional_response_data: [
        {
          name: "company_name",
          type: "string",
          optional: false,
          description: "Name of the entity",
        },
        {
          name: "taxpayer",
          type: "object",
          optional: true,
          description: "",
          object_fields: [
            { name: "address", type: "string", optional: true, description: "" },
            { name: "registration_date", type: "date", optional: true, description: "" },
          ],
        },
      ],
      has_individual_tin_format_checksum_support: false,
      has_individual_tin_online_validation_support: false,
      has_local_source_without_tax_registered_support: false,
    },
  },
};

export const stubReverseLookupCountries: Record<string, ReverseLookupCountryData> = {
  au: {
    country_iso_code: "au",
    country_name: "Australia",
    minimum_input_length: 1,
  },
  sg: {
    country_iso_code: "sg",
    country_name: "Singapore",
    minimum_input_length: 5,
  },
};

export const stubReverseLookupSupportedCountries: ReverseLookupSupportedCountries = {
  availableCountries: stubReverseLookupCountries,
  allCountries: {
    ...stubReverseLookupCountries,
    pl: {
      country_iso_code: "pl",
      country_name: "Poland",
      minimum_input_length: 1,
    },
  },
};
